import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Settings, { Setting } from '../../components/settings';
import { get, post, put } from '../../api';
import { OrderTypeEntry } from '../../components/orders/orderType';

export default function SettingsContainer () {

  const [settings, setSettings] = useState<Setting[]>([]);
  const [error, setError] = useState('');

  const settingUpdateHandler = async (setting: Setting) => {

    const previousState = [...settings];


    const newSettings = settings.map(s => {

      if (s.id === setting.id) {
        s = setting;
      }

      return s;
    });
    setSettings(newSettings);

    const response = await put('settings/' + setting.id, setting);

    if (response.status !== 200) {
      setSettings(previousState);
      setError('Failed to update setting');
    }
  };

  const loadSettings = async () => {

    const response = await get('settings');

    
    if (response.status === 200) {

      setSettings(response.data);

    } else {
      setError('Failed to load location data');
    }
  
    return;
  };

  useEffect(()=>{
    loadSettings();
  }, []);
  
  return (<Settings settings={settings} onUpdate={settingUpdateHandler} error={error} />);
}