import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import MultiSelectChip from '../input/multiSelectChip';
import { SelectType } from '../types';
import { FormControlLabel, makeStyles, Switch, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { ClassNames } from '@emotion/react';
import { get, post } from '../../../api';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCartridgeFilterData } from '../../../features/cartridgeFilterData/cartridgeFilterDataSlice';

type InitialFilterValues = {
  companies?: number[],
  locations?: number[],
  cartridgeTypes?: string[],
  devices?: string[],
  date?: Date[],
  dateEnabled?: boolean
}

type ComponentProps = {
    /*filterValues: {
        companies: SelectType[],
        locations: SelectType[],
        cartridgeTypes: SelectType[],
        devices: SelectType[],
        date: Date[]
    },
    options: {
        companies: SelectType[],
        locations: SelectType[],
        cartridgeTypes: SelectType[],
        devices: SelectType[],
    },*/
    onSetFilters?: (filters: ICartridgeFilterState ) => void,
    onSetFilter?: (filter: DeviceCartFilterType, value: any[]) => void, 
    initialFilterValues?: InitialFilterValues,
    showDatePicker?: boolean,
    showDevice?: boolean
    showCartridgeTypes?: boolean,
    showDatePickerAwlays?: boolean,
    singleLocation?: boolean,
    singleCustomer?: boolean,
    singleDevice?: boolean,
    sequencialFieldUnlock?: boolean,
    datePickerFormat?: DatePickerFormat,
    setIsLocationSelected?: (value: boolean) => void,
    updateData?: () => void,
}

export interface ICartridgeFilterState {
  companies: number[],
  locations: number[],
  cartridgeTypes: string[],
  devices: string[],
  date: Date[],
  dateEnabled: boolean,
}


export enum DeviceCartFilterType {
    companies = 'companies',
    locations = 'locations',
    cartridgeTypes = 'cartridgeTypes',
    devices = 'devices',
    date = 'date',
    dateEnabled = 'dateEnabled'
  }
/*
type SearchParamsUrlMap = {
  'filterCompanies': DeviceCartFilterType.companies,
  'filterLocations': DeviceCartFilterType.locations,
  'filterCartTypes': DeviceCartFilterType.cartridgeTypes,
  'filterDevices': DeviceCartFilterType.devices,
  'filterDate': DeviceCartFilterType.date,
  'filterDateEnabled': DeviceCartFilterType.dateEnabled,
};

type ReverseMap<T extends Record<keyof T, keyof any>> = {
  [P in T[keyof T]]: {
      [K in keyof T]: T[K] extends P ? K : never
  }[keyof T]
}

type ParamsUrlSearchMap = ReverseMap<SearchParamsUrlMap>*/

export enum DatePickerFormat {
  DAY='DAY',
  MONTH='MONTH',
}

export default function CartridgesFilter (props: ComponentProps) {
    
  const defaultProps = {
    showDatePicker: false,
    showDevice: true,
    showCartridgeTypes: true,
    showDatePickerAwlays: false,
    datePickerFormat: DatePickerFormat.MONTH,
    singleCustomer: false,
    singleLocation: false,
    singleDevice: false,
    sequencialFieldUnlock: false,
  };

  props = { ...defaultProps, ...props };

  const dispatch = useDispatch();

  const { onSetFilters, onSetFilter, initialFilterValues, showDatePicker, showDevice, showCartridgeTypes, showDatePickerAwlays, datePickerFormat, singleCustomer, singleLocation, singleDevice } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const filterValues: ICartridgeFilterState = {
    companies: [],
    locations: [],
    cartridgeTypes: [],
    devices: [],
    date: [new Date()],
    dateEnabled: showDatePickerAwlays ? true : false,
  };

  searchParams.forEach((value, key: any) => {

    const filterKey: DeviceCartFilterType = key as DeviceCartFilterType;

    switch (filterKey) {
      case DeviceCartFilterType.companies:
      case DeviceCartFilterType.locations:
      case DeviceCartFilterType.cartridgeTypes:
      case DeviceCartFilterType.devices:
        filterValues[filterKey] = JSON.parse(value);
        break;
      case DeviceCartFilterType.date:
        filterValues[filterKey] = [new Date(JSON.parse(value))];
        break;
      case DeviceCartFilterType.dateEnabled:

        if (showDatePickerAwlays) {
          value = 'true';
        }

        filterValues[filterKey] = Boolean(JSON.parse(value));
        break;
    }
    
  });

  const [options, setOptions] = useState({
    companies: [],
    locations: [],
    cartridgeTypes: [],
    devices: [],
  });

  const setFilterValues = (values: any) => {

    const searchParams: {[key: string]: any} = {};

    for (const key in values) {
      searchParams[key] = JSON.stringify(values[key]);
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (initialFilterValues) {
      setFilterValues({ ...filterValues, ...initialFilterValues });
      getFilerOptions();
    }
    
  },[initialFilterValues?.locations]);

  const initialFilterSetCallbacks = () => {
    let filtersEnabled = false;
    for (const key in filterValues) {

      const filterValue = filterValues[key as DeviceCartFilterType];

      if (typeof filterValue !== 'boolean' && filterValue.length > 0) {

        if (onSetFilter) {
          if ( key !== DeviceCartFilterType.date) {

            const filterValueV: any[] = filterValue;

            onSetFilter(
              key as DeviceCartFilterType, 
              filterValueV.map((x: any)=>{ 
                return { id: x, name: '' }; 
              })
            );
          } else {
            onSetFilter(key as DeviceCartFilterType, filterValue);
          }
        }
        filtersEnabled = true;
      } else if (filterValue && key === DeviceCartFilterType.dateEnabled) {
        if (onSetFilter) {
          onSetFilter(DeviceCartFilterType.date, [filterValues.date]);
          filtersEnabled = true;
        }
      }
    }
    
    if (filtersEnabled && onSetFilters) {
      onSetFilters(filterValues);
    }
  };

  const getFilerOptions = async () => {

    let filters = '';

    console.log('filterValues', filterValues)
    if (filterValues.cartridgeTypes.length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'cartridgeTypes='+encodeURIComponent(JSON.stringify(filterValues.cartridgeTypes));
    }
    if (filterValues.devices.length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'devices='+encodeURIComponent(JSON.stringify(filterValues.devices));
    }
    if (filterValues.companies.length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'companies='+ encodeURIComponent(JSON.stringify(filterValues.companies));
    }
    if (filterValues.locations.length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'locations='+encodeURIComponent(JSON.stringify(filterValues.locations));
    }

    const result = await get('cartridges-filter' + filters);

    if (result.status === 200) {

      dispatch(setCartridgeFilterData(result.data));
      setOptions(result.data);
    } 

    return result;
  };
  
  const filterSelectHandler = (filterType: DeviceCartFilterType, value: any) => {

    if (onSetFilter) {
      onSetFilter(filterType, value);
    }

    if (filterType === DeviceCartFilterType.companies && singleCustomer) {

      filterValues[DeviceCartFilterType.locations] = [];
    }

    if (filterType !== DeviceCartFilterType.dateEnabled && filterType !== DeviceCartFilterType.date) {
      filterValues[filterType] = value.map((x: SelectType)=>x.id ? x.id : x);
    } else {
      filterValues[filterType] = value;
    }


    setFilterValues({ ...filterValues });

    if (onSetFilters) {
      onSetFilters(filterValues);
    }


    if (filterType !== DeviceCartFilterType.date && filterType !== DeviceCartFilterType.dateEnabled) {
      getFilerOptions();
    }
    
    return;
  };

  const ini = async () => {

    await getFilerOptions();

    initialFilterSetCallbacks();
  };

  useEffect(()=>{

    ini();
  }, []);

  useEffect(() => {
    if (filterValues.locations) {
      if (filterValues.locations.length > 0) {
        props.setIsLocationSelected && props.setIsLocationSelected(true)
      } else {
        props.setIsLocationSelected && props.setIsLocationSelected(false)
      }
    }
  }, [filterValues]);

  if(window.location.pathname.includes('orders-debug')) {
    return <></>
  }

  return <Box sx={{ padding: (theme) => theme.spacing(2, 2, 2, 0), display: 'flex' }}>
    <MultiSelectChip 
      label={!singleCustomer ? 'Customers' : 'Customer'}
      multiple={!singleCustomer}
      value={filterValues ? filterValues.companies : initialFilterValues?.companies ? initialFilterValues.companies : []}
      selectOptions={options.companies}
      onSelectOption={(values)=>{
        console.log('values', values)
        filterSelectHandler(DeviceCartFilterType.companies, values)
        props.setIsLocationSelected && props.setIsLocationSelected(false)
        console.log('updateData')
        props.updateData && props.updateData()
      }}
    />
    <MultiSelectChip 
      label={!singleLocation ? 'Locations' : 'Location'}
      value={filterValues ? filterValues.locations : initialFilterValues?.locations ? initialFilterValues.locations : []}
      multiple={!singleLocation}
      selectOptions={options.locations}
      onSelectOption={(values)=>{
        filterSelectHandler(DeviceCartFilterType.locations, values)
        props.setIsLocationSelected && props.setIsLocationSelected(true);
        console.log('updateData')
        props.updateData && props.updateData()
      }}
      disabled={!options.locations.length}
    />
    {showDevice ? 
      <MultiSelectChip 
        label={!singleDevice ? 'Devices' : 'Device'}
        value={filterValues?.devices}
        multiple={!singleDevice}
        selectOptions={options.devices}
        onSelectOption={(values)=>filterSelectHandler(DeviceCartFilterType.devices, values)}
      />
      : null}
    {showCartridgeTypes ? 
      <MultiSelectChip 
        label="Cartridge Types"
        value={filterValues?.cartridgeTypes}
        selectOptions={options.cartridgeTypes}
        onSelectOption={(values)=>filterSelectHandler(DeviceCartFilterType.cartridgeTypes, values)}
      />
      : null}
    {showDatePicker || showDatePickerAwlays ? 
      <>
        {!showDatePickerAwlays ? <Box sx={{ padding: (theme)=>theme.spacing(1, 0, 0, 0) }}><FormControlLabel
          control={<Switch checked={filterValues?.dateEnabled} onChange={
            ()=>{

              const newState = { ...filterValues, dateEnabled: !filterValues?.dateEnabled };

              if (onSetFilter) {
                if (!filterValues?.dateEnabled) {
                  onSetFilter(DeviceCartFilterType.date, [filterValues?.date]);
                } else {
                  onSetFilter(DeviceCartFilterType.date, [undefined]);
                }
                
              }

              setFilterValues(newState);
            }
          } />}
          label="Filter Date"
        /></Box> : null}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disabled={!filterValues?.dateEnabled}
            views={datePickerFormat === DatePickerFormat.MONTH ? ['year', 'month'] : ['year', 'month', 'day'] }
            openTo={datePickerFormat === DatePickerFormat.MONTH ? 'month' : 'day'}
            renderInput={(params) => (
              <TextField
                sx={{ margin: (theme: any) => theme.spacing(1, 0, 0, 0), width: 200 }}
                size="small"
                {...params}
              />
            )}
            label="Date"
            inputFormat={datePickerFormat === DatePickerFormat.MONTH ? 'MM.yyyy' : 'dd.MM.yyyy'}
            value={filterValues?.date?.length > 0 ? filterValues?.date[0] : undefined}
            onChange={(values: any)=>filterSelectHandler(DeviceCartFilterType.date, [values])}
          />
        </LocalizationProvider>
      </>
      : null}

    {/*<WideButton sx={{ margin: (theme: Theme)=>theme.spacing(2) }} disabled={deviceRefresh}>Refresh</WideButton>*/}
    {/*<FormControlLabel
      control={<Switch checked={filters.onlyActive} onChange={()=>setFilters({ ...filters, onlyActive: !filters.onlyActive })} />}
      label="Only Active"
/>*/}
  </Box>;
}