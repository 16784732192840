import { Alert, Box, Container, Grid, TextField, Typography } from '@mui/material';
import Location, { LocationPropps, LocationFormType } from './location';
import React, { useState } from 'react';
import { useTheme } from '@mui/system';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import SelectCountry from '../../features/countries/selectContainer';
import TextFieldCP from '../common/input/textField';
import { WideButton } from '../common/button';

export enum AddFormLocationStatus {
  EDIT = 'EDIT',
  ADDED = 'ADDED'
}

type LocationProps = {
  status: AddFormLocationStatus,
  loading: boolean,
  error: string,
  message: string,
  formData: LocationFormType,
  setFormData: (data: LocationFormType) => void,
  onAdd: (data: LocationFormType) => Promise<void>,
}

const LocationAdd = ({ onAdd, error, loading, status, message, formData, setFormData }: LocationProps) => {



  const theme = useTheme();

  return <Container>
    <PageHeader
      title="Add location"
      subTitle="Add delivery address"
    />
    <LoadingBar loading={loading} />
    <Box style={{
      maxWidth: 600
    }}>
      {error ? <Grid item><Alert severity="error">{error}</Alert></Grid> : null}
      {message ? <Grid item padding={theme.spacing(0,0,1,0)}><Alert severity="success">{message}</Alert></Grid> : null}
      <Grid
        container 
        spacing={1}
      >
        <Grid item md={12} sm={12} xs={12}>
          <TextFieldCP
            label={'Location name'}
            value={formData.name}
            fullWidth
            onChange={(event: any) => setFormData({ ...formData, ...{ name: event?.target?.value } })}
          /> 
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCP
            fullWidth
            label={'Address line 1'}
            value={formData.street}
            onChange={(event: any) => setFormData({ ...formData, ...{ street: event?.target?.value } })} 
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCP fullWidth label={'Address line 2'} value={formData.street2}
            onChange={(event: any) => setFormData({ ...formData, ...{ street2: event?.target?.value } })} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCP fullWidth label={'Zip'} value={formData.zip}
            onChange={(event: any) => setFormData({ ...formData, ...{ zip: event?.target?.value } })} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCP fullWidth label={'City'} value={formData.city}
            onChange={(event: any) => setFormData({ ...formData, ...{ city: event?.target?.value } })} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <SelectCountry fullWidth label={'Country'} value={formData.country_id}
            onChange={(id: number) => setFormData({ ...formData, ...{ country_id: id } })} />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <WideButton disabled={loading} onClick={()=>{ onAdd(formData); }}>Add</WideButton>
        </Grid>
      </Grid>
    </Box>
  </Container>;
};

export default LocationAdd;