import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectType } from '../../components/common/types';
import { get } from '../../api';
import { RootState, AppThunk } from '../../app/store';

export interface CountriesState {
  data: SelectType[];
  loaded: boolean,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CountriesState = {
  data: [],
  status: 'idle',
  loaded: false
};


export const fetchCountriesOptions = createAsyncThunk(
  'countries/fetch',
  async () => {
    
    const response = await get('countries');
    return response.data;
  }
);


export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCountriesOptions.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
      });
  },
});

export const selectData = (state: RootState) => state.countries.data;
export const isLoaded = (state: RootState) => state.countries.loaded;

export default countriesSlice.reducer;