import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export enum SnackState {
    HIDDEN,
    SUCCESS,
    WARN,
    ERROR,
    INFO
}

type Props = {
    snackState: SnackState,
    includeBanner?: boolean,
    snackText: string,
    onClose: () => void
}

const getSeverity = (snackState: SnackState) => {

  switch (snackState) {
    case SnackState.SUCCESS: return 'success';
    case SnackState.WARN: return 'warning';
    case SnackState.ERROR: return 'error';
    case SnackState.INFO: return 'info';
    default: return 'info';
  }
};

export default function SnackBar({ snackState, onClose, snackText }: Props) {


  return <Stack spacing={2} sx={{ width: '100%' }}>
    <Snackbar 
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackState !== SnackState.HIDDEN} 
      autoHideDuration={2000} 
      onClose={onClose}>
      <Alert onClose={onClose} severity={getSeverity(snackState)} sx={{ width: '100%' }}>
        {snackText}
      </Alert>
    </Snackbar>
  </Stack>;
}