import { Alert, Box, Container, Typography } from '@mui/material';
import Location, { LocationPropps, LocationFormType } from './location';
import React from 'react';
import { useTheme } from '@mui/system';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';

type LocationProps = {
  error: string,
  loading: boolean,
  locations: Array<LocationPropps>,
  onChangeDevice: (id: number, data: Array<SelectType>) => Promise<void>,
  onChangeResponsible: (id: number, data: Array<SelectType>) => Promise<void>,
  onChangeLocation: (id: number, data: LocationFormType) => Promise<void>,
  allDevices: Array<SelectType>,
  allResponsibles: Array<SelectType> 
}

const Locations = ({ error, loading, allDevices, allResponsibles, locations, onChangeDevice, onChangeResponsible, onChangeLocation }: LocationProps) => {

  const theme = useTheme();

  return <Container>
    <PageHeader
      title="Locations"
      subTitle="See your delivery addresses & assigned local contact persons"
    />
    <LoadingBar loading={loading} />
    {error ? <Alert severity="error">{error}</Alert> : null}
    <Box style={{
      display: 'flex', 
      justifyContent: 'left', 
    }}>
      <Box style={{
        display: 'flex', 
        padding: theme.spacing(0,0,10,0),
        justifyContent: 'left', 
        flexWrap: 'wrap', 
        alignItems: 'strech',
        gap: '10px',
        rowGap: '10px',
        columnGap: '20px'
      }}>{locations.map((x: LocationPropps, i: number) => <Box
          key={i}
        >
          <Location
            onLocationSave={onChangeLocation}
            id={x.id}
            name={x.name}
            street={x.street}
            street2={x.street2}
            country={x.country}
            country_id={x.country_id}
            zip={x.zip}
            city={x.city}
            responsibles={x.responsibles}
            allResponsibles={allResponsibles}
            allDevices={allDevices}
            devices={x.devices}
            onChangeDevice={onChangeDevice}
            onChangeResponsible={onChangeResponsible}
          /></Box>)}</Box></Box>
  </Container>;
};

export default Locations;