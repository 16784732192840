import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Settings, { Setting } from '../../components/settings';
import { get, post, put } from '../../api';
import { OrderTypeEntry } from '../../components/orders/orderType';
import StatsDashboard from '../../components/statsDashboard';
import Customers, { ICustomersDataEntry } from '../../components/customers';
import { useNavigate } from 'react-router-dom';

export default function CustomersContainer () {

  const [data, setData] = useState<ICustomersDataEntry[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const navigateHandler = (url: string) => {

    navigate(url);
  };

  const getDashboardUrl = async () => {

    setLoading(true);

    const response = await get('customers');

    setLoading(false);

    if (response.status === 200) {

      setData(response.data.data);

    } else {
      setError('Failed to load customers data');
    }
  
    return;
  };

  useEffect(()=>{
    getDashboardUrl();
  }, []);
  
  return (<Customers 
    loading={loading}
    navigate={navigateHandler}
    data={data} 
    error={error} 
  />);
}