import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../api';
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { LocationFormType } from '../../components/locations/location';
import LocationEdit from '../../components/locations/editLocation';

const EditLocationContainer = () => {


  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<LocationFormType>({ name: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
    country: '',
    country_id: '', });
  const [error, setError] = useState('');

  const { clientId, locationId } = useParams();

  const loadDataHandler = async () => {

    setLoading(true);
    const response = await get('companies/' + clientId + '/locations/' + locationId).catch(console.error);
    setLoading(false);
    if (response?.status === 200) {
      
      setError('');
      setData(response.data);
    } else {
      setError('Failed to update location data');
    }

    return;
  };

  const locationEditHandler = async (data: LocationFormType) => {

    setLoading(true);
    const response = await put('companies/' + clientId + '/locations/' + locationId, data).catch(console.error);
    setLoading(false);
    if (response?.status === 200) {
      setError('');
      //setData(response.data.data);
    } else {
      setError('Failed to update location data');
    }

    return;
  };

  useEffect(()=>{

    loadDataHandler();
  },[]);

  return (
    <LocationEdit defaultValues={data} error={error} loading={loading} onEdit={locationEditHandler} />
  );
};

export default EditLocationContainer;