import { LocationStock } from '../../components/devices';
import CartridgeBox from '../../components/devices/cartridgeBox';
import { DeviceCartridgeData, getAllCartridgeUsages, getDevices, getStock, getSuggestedOrders, setLoading, setSnack, setStock, setSuggestedOrder } from './devicesSlice';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../api';
import { SnackState } from '../../components/common/snackBar';
import { Box } from '@mui/material';
import { Setting } from '../../components/settings';

type Props = {
    deviceCartridgeData: DeviceCartridgeData,
    locationId: number,
    deviceId: string,
    inSlot: boolean,
    settings?: Setting[]
}

let timeout: ReturnType<typeof setTimeout>;

let abortController: AbortController;

export default function CartridgeDevicesContainer({ deviceCartridgeData, locationId, deviceId, inSlot, settings }: Props) {

  const stockData = useSelector(getStock);
  const suggestedOrders = useSelector(getSuggestedOrders);
  const allCartridgeUsages = useSelector(getAllCartridgeUsages);
  const dispatch = useDispatch();

  const [internalLoading, setInternalLoading] = useState(false); //loading state in box

    
  const cartridgeUpdateHandler = async (locationId: number, cartridgeType: string, value: number, isIncrease: boolean ) => {
    

    clearTimeout(timeout);

    dispatch(setStock({ locationId: locationId, cartType: cartridgeType, value: value }));

    if (!(!isNaN( +value ) && value !== null)) {
      return;
    }

    dispatch(setLoading(true)); //general loading in devices view
    setInternalLoading(true);

    if (abortController) {
      abortController.abort();
    }

    timeout = setTimeout(async () => {

      abortController = new AbortController();
      const response = await post('location/' + locationId + '/stock-cartridges/', [{ cartrigeCount: isIncrease ? 1 : -1, cartridgeType: cartridgeType }], { signal: abortController.signal });

      if (!response) {

        dispatch(setSnack({ snack: SnackState.ERROR, message: 'Could not update stock!' }));

        return false;
      }

      if (response && response.status !== 200) {

        dispatch(setSnack({ snack: SnackState.ERROR, message: 'Could not update stock! ' + (response.message ? response.message : '') }));

        return false;
      }

      if (response && response.status === 200) {

        dispatch(setSnack({ snack: SnackState.SUCCESS, message: 'Stock updated!' }));

        // const refreshResponse = await post('refresh-pending-order-cache', { locationIds: [locationId], cartridgeTypes: [cartridgeType] });

        // if (refreshResponse && refreshResponse.status === 200) {

        //   const pendingOrderCnt = refreshResponse?.data?.[locationId]?.[0]?.cartridgeOrderCount;

        //   //  const newLocationsSuggestedOrder = { ...suggestedOrders, ...{ [locationId]: { [cartridgeType]: pendingOrderCnt } } };


        //   dispatch(setSuggestedOrder({ locationId, cartType: cartridgeType, value: pendingOrderCnt }));
        // } else {

        //   dispatch(setSnack({ snack: SnackState.ERROR, message: 'Failed to refresh pending cache!' }));
        // }
      }

      abortController;

      dispatch(setLoading(false));
      setInternalLoading(false);


    }, 500);

    return true;
  };
  

  const cartridgeCount = stockData[locationId]?.[deviceCartridgeData.cartridgeType];
  const suggestedOrder = suggestedOrders[locationId]?.[deviceCartridgeData.cartridgeType];  
  const cartridgeUsage = allCartridgeUsages && allCartridgeUsages[locationId] ? allCartridgeUsages[locationId]?.[deviceCartridgeData.cartridgeType] : null;


  return useMemo(()=> <CartridgeBox
    inSlot={inSlot}
    loading={internalLoading}
    deviceCartridgeData={deviceCartridgeData}
    inputBoxActive={typeof suggestedOrder !== undefined}
    locationId={locationId}
    deviceId={deviceId}
    onUpdateStock={cartridgeUpdateHandler}
    suggestedOrder={suggestedOrder}
    cartridgeCount={cartridgeCount}
    cartridgeUsage={cartridgeUsage}
    settings={settings}
  />, [inSlot, internalLoading, deviceCartridgeData, locationId, deviceId, suggestedOrder, cartridgeCount]);
}