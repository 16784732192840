import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { CartridgeFilterData } from './cartridgeFilterDataTypes';

const cartrigeFilterData: CartridgeFilterData = {
    locations: [],
    companies: [],
    cartridgeTypes: [],
    devices: [],
    cartridgeTypeDemand: {},
}

const initialState = { cartrigeFilterData }

export const cartrigeFilterDataSlice = createSlice({
  name: 'cartrigeFilterData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCartridgeFilterData: (state, param) => {
        const { payload } = param;
        state.cartrigeFilterData = {...payload};
    },
  },
});

export const selectCartridgeFilterData = (state: RootState) => state.cartridgeFilterData.cartrigeFilterData;

const { actions, reducer } = cartrigeFilterDataSlice
export const { setCartridgeFilterData } = actions;

export default reducer;
