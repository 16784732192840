// POST method implementation:
import { useEffect, useState } from 'react';
import { BACKEND_API_HOST, BACKEND_API_HOST_TEST } from '../settings';

const HTTP_API_PORT = 3001;
//export let API_URL = "http://localhost:3001"
export let API_URL = process.env.NODE_ENV === 'production' ? 
  window.location.protocol + '//' + BACKEND_API_HOST : window.location.protocol + '//' + window.location.hostname + ':' + HTTP_API_PORT;

if (window.location.hostname.includes('appdomain.cloud')) {
  API_URL = window.location.protocol + '//' + BACKEND_API_HOST_TEST;
}

type options = {
  signal?: AbortSignal
}

export const post = (url = '', data = {}, options: options = {}) => {

  return fetch(API_URL + '/' + url, {
    method: 'POST',
    credentials: 'include',
    signal: options.signal ? options.signal : null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'mode': 'cors',
    },
    body: JSON.stringify(data) }).then(async (resp) => {

    const data = await resp.json();

    return { ...data, ...{ status: resp.status, data: data } };
  }).catch(async (resp) => {

    return { httpSatus: resp.status, status: resp.status, data: { message: 'Request failed' } };
  });
};

//Get method hook
export const useGet = (url = '') => {

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {

    const controller = new AbortController;
    setLoading(true);
    fetch(API_URL + '/' + url, {
      signal: controller.signal,
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'mode': 'cors',
      },
    }).then(async (resp) => {
      
      if (resp.status === 401) {
        window.location.href = '/';
        return { status: 401, message: 'Unauthorized' };
      }
  
      const data = await resp.json();
  
      setLoading(false);

      setResponse({ status: resp.status, data: data });

      return resp;
    }).catch(async (resp) => {
  
      setResponse({ status: resp.status, data: { message: 'Request failed' } });

      return resp;
    });

    return () => {
      controller.abort();
    };

  }, [url]);

  return { response, loading };
  
};

// GET method implementation:
export const get = (url = '') => {

  return fetch(API_URL + '/' + url,{
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'mode': 'cors',
    },
  }).then(async (resp) => {
    
    if (resp.status === 401) {
      window.location.href = '/';
      return { status: 401, message: 'Unauthorized' };
    }

    const data = await resp.json();

    return { ...data, ...{ httpSatus: resp.status, status: resp.status, data: data } };
  }).catch(async (resp) => {

    return { httpSatus: resp.status, status: resp.status, data: { message: 'Request failed' } };
  });
};

// DELETE method implementation:
export const remove = ((url = '', data = {}): Promise<{httpSatus?: number, status: number, data?: {message: string}}> => {

  return fetch(API_URL + '/' + url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'mode': 'cors',
    },
    body: JSON.stringify(data) }).then(async (resp) => {

    const data = await resp.json();

    return { status: resp.status, data: data };

  }).catch(async (resp) => {

    return { httpSatus: resp.status, status: parseInt(resp.status), data: { message: 'Request failed' } };
  });
});

// PUT method implementation:
export const put = (url: string, data: any) => {

  return fetch(API_URL + '/' + url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'mode': 'cors',
    },
    body: JSON.stringify(data) }).then(async (resp) => {

    const data = await resp.json();

    return { ...data, status: resp.status, httpSatus: resp.status, data: data };
  }).catch(async (resp) => {

    return { httpSatus: resp.status, status: resp.status, data: { message: 'Request failed' } };
  });
};