import { Alert, Box, Button, Container, Dialog, DialogTitle, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { API_URL } from '../../api';
import PageHeader from '../common/page/pageHeader';

export default function StatsDashboard({ url, error }: {url: string, error?: string}) {

  const theme = useTheme();

  return <Container sx={{ padding: theme.spacing(0, 6) }}>
    <PageHeader
      title="H2YO Dashboard POC" 
      subTitle={<>View data of H2YO in PowerBi dashboard</>}
    />
    {error ? <Box><Alert severity="error">{ error }</Alert></Box> : null}
    <Box>
      {url ? <iframe title="H2YO Dashboard POC" width="100%" height="800px" src={url} frameBorder="0" allowFullScreen={true} /> : null}
    </Box>
  </Container>;
}