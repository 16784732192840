import { Box, Menu, Typography } from '@mui/material';
import React from 'react';
//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import LoginContainer from '../features/login/container';
import Shop from '../components/shop';
import MenuTabs from '../components/menu';
import { useSelector } from 'react-redux';
import { getPermissions } from '../features/user/userSlice';
import RegisterContainer from '../features/user/registerCompanyContainer';
import Invite from './invite/index';
import PasswordResetEmailContainer from '../features/user/passwordResetEmailContainer';
import PasswordResetPasswordContainer from '../features/user/passwordResetPasswordContainer';
import PublicPage from './common/publicPage';
import AppBarComp from './appBar';
import CompanyAccountContainer from '../features/user/companyAccountContainer';
import Dashboard from '../features/dashboard/container';
import UserProfileContainer from '../features/user/userProfileContainer';
import CartrigesContainer from '../features/cartriges/container';
import DebugContainer from '../features/debug/container';
import ConfirmEmailContainer from '../features/user/confirmEmailContainer';
import LocationsContainer from '../features/locations/container';
import OrdersContainer from '../features/orders/container';
import TeamContainer from '../features/team/container';
import PageFooter from './common/publicPage/footer';
import CustomerSupport from './customerSupport';
import Page from './common/page';
import RegisterUserContainer from '../features/user/registerUserContainer';
import ClientCompaniesContainer from '../features/clientCompanies/container';
import AutoLoginContainer from '../features/login/autoLoginContainer';
import DevicesContainer from '../features/devices/container';
import InviteHod from './invite/hod';
import OrderDebugComponent from './debug/debugOrders';
import SettingsContainer from '../features/settings/container';
import StatsDashboard from './statsDashboard';
import StatsDashboardContainer from '../features/statsDashboard/container';
import Customers from './customers';
import CustomersContainer from '../features/customers/container';
import DeviceCartridgesContainer from '../features/deviceCartridges/container';
import AddLocationContainer from '../features/clientCompanies/addLocationContainer';
import EditLocationContainer from '../features/clientCompanies/editLocationContainer';
import EditClientContainer from '../features/clientCompanies/editClientContainer';
import DevicesHodsContainer from '../features/devicesHods/container';
import DevicesEventsContainer from '../features/devicesEvents/devicesEvents';
import CartridgesEventsContainer from '../features/cartridgesEvents/container';
import HodsContainer from '../features/companySettings/container';
import CompanySettingsContainer from '../features/companySettings/containerSingle';
import PrivacyPolicy from './terms/privacyPolicy';
import TermsAndConditions from './terms/terms';
import StockLogContainer from '../features/stockLog/container';
import LocationLogComponent from './locationLog';
import LocationLogContainer from '../features/locationLog';


function App() {

  const permissions = useSelector(getPermissions);

  return (
    <>
      <BrowserRouter>
        <AutoLoginContainer />
        <Routes>
          <Route path="/device-cartridges" element={<Page><DeviceCartridgesContainer /></Page>} />
          <Route path="/settings" element={<Page><SettingsContainer /></Page>} />
          {permissions.indexOf('MANAGE_ALL_DEVICES') > -1 ? <Route path="/devices-hods" element={<Page><DevicesHodsContainer /></Page>} /> : null}
          <Route path="/devices-events/:deviceSN" element={<Page><DevicesEventsContainer /></Page>} />
          <Route path="/cartridge-events/:tagId/:deviceId" element={<Page><CartridgesEventsContainer /></Page>} />
          <Route path="/stock-log" element={<Page><StockLogContainer /></Page>} />
          <Route path="/location-log" element={<Page><LocationLogContainer /></Page>} />
          <Route path="/stats-dashboard" element={<Page><StatsDashboardContainer /></Page>} />
          <Route path="/order-tracking" element={<Page><CustomersContainer /></Page>} />
          <Route path="/hods" element={<Page><HodsContainer /></Page>} />
          <Route path="/orders-debug" element={<Page><OrderDebugComponent /></Page>} />
          <Route path="/orders-debug/:locationId" element={<Page><OrderDebugComponent /></Page>} />
          <Route path="/client/:clientId" element={<Page><EditClientContainer /></Page>} />
          <Route path="/client/:clientId/location" element={<Page><AddLocationContainer /></Page>} />
          <Route path="/client/:clientId/location/:locationId" element={<Page><EditLocationContainer /></Page>} />
          <Route path="/debug" element={<Page><DebugContainer /></Page>} />
          <Route path="/company-settings/:companyId" element={<Page><CompanySettingsContainer /></Page>} />
          <Route path="/locations" element={<Page><LocationsContainer /></Page>} />
          <Route path="/cartriges" element={<Page><CartrigesContainer /></Page>} />
          {/* <Route path="/customer-support" element={<Page><CustomerSupport /></Page>} /> */}
          <Route path="/orders" element={<Page><OrdersContainer /></Page>} />
          <Route path="/services" element={<Page><Box>Services content</Box></Page>} />
          <Route path="/manage-devices" element={<Page><DevicesContainer /></Page>} />
          <Route path="/profile" element={<Page><UserProfileContainer /></Page>} />
          {permissions.indexOf('MANAGE_TEAM') > -1 ? <Route path="/team-management" element={<Page><TeamContainer /></Page>} /> : null}
          <Route path="/company-account" element={<Page><CompanyAccountContainer /></Page>} />
          <Route path="/clients" element={<Page><ClientCompaniesContainer /></Page>} />
          <Route path="/privacy-policy" element={<Page><PrivacyPolicy /></Page>} />
          <Route path="/terms-and-conditions" element={<Page><TermsAndConditions /></Page>} />
          <Route path="/resetpassword" element={<>
            <PublicPage><PasswordResetEmailContainer /></PublicPage>
          </>} />
          <Route path="/confirmemail/:token/:email" element={<>
            <PublicPage><ConfirmEmailContainer /></PublicPage>
          </>} />
          <Route path="/setnewpassword/:token/:email" element={<>
            <PublicPage><PasswordResetPasswordContainer /></PublicPage>
          </>} />
          <Route path="/newpassword" element={<></>} />
          <Route path="/invite/accept" element={<>
            <PublicPage includeBanner={false} isRegister><RegisterContainer /></PublicPage>
          </>} />
          <Route path="/user-invite/accept" element={<>
            <PublicPage includeBanner={false} isRegister><RegisterUserContainer /></PublicPage>
          </>} />
          <Route path="/invite" element={<><AppBarComp /><Invite /></>} />
          <Route path="/invite-hod" element={<><AppBarComp /><InviteHod /></>} />
          <Route path="/" element={
            <PublicPage><LoginContainer /></PublicPage>
          } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
