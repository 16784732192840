import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { get } from '../../api';
import { DeviceCartFilterType } from '../../components/common/cartridgesFilter';
import { SelectType } from '../../components/common/types';
import DeviceCartridgesComponent, { FilterProps, FilterOptionsProps } from '../../components/deviceCartridges';


export default function DeviceCartridgesContainer () {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [filterValues, setFilterValues] = useState<FilterProps>({
    devices: [],
    companies: [],
    locations: [],
    cartridgeTypes: [],
    date: undefined
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>({
    devices: [],
    companies: [],
    locations: [],
    cartridgeTypes: [],
  });

  const [searchParams, setSearchParams] = useSearchParams();
  
  const setFiltersHandler = async (filterType: DeviceCartFilterType, values: SelectType[] | Date[]) => {

    let dateValues;

    switch (filterType) {
      case DeviceCartFilterType.cartridgeTypes:
      //  setFilterValues({ ...filterValues, ...{ cartridgeTypes: values } });
        setFilterValues({ ...filterValues, ...{ cartridgeTypes: values as SelectType[] } });
        break;
      case DeviceCartFilterType.companies:

        if (values.length === 0 || filterValues.companies.length === 0) {
          setFilterValues({ ...filterValues, ...{ companies: values as SelectType[], locations: [], devices: [] } });
        } else {
          setFilterValues({ ...filterValues, ...{ companies: values as SelectType[] } });
        }
        
        break;
      case DeviceCartFilterType.devices:
        setFilterValues({ ...filterValues, ...{ devices: values as SelectType[] } });
        break;
      case DeviceCartFilterType.locations:
        setFilterValues({ ...filterValues, ...{ locations: values as SelectType[] } });
        break;
      case DeviceCartFilterType.date:

        dateValues = values as Date[];

        setFilterValues({ ...filterValues, ...{ date: dateValues[0] } });
        break;
    }
  };

  const fetchData = async() => {

    let filters = '';

    const devicesFilter = searchParams.get('devices');
    const cartridgeFilter = searchParams.get('cartridgeTypes');
    const companiesFilter = searchParams.get('companies');
    const locationsFilter = searchParams.get('locations');
    const dateEnabled = searchParams.get('dateEnabled');
    const dateFilter = searchParams.get('date');

    if (cartridgeFilter && JSON.parse(cartridgeFilter).length > 0) {
      filters += filters === '' ? '?' : '&';
      filters += 'cartridgeTypes='+encodeURIComponent(cartridgeFilter);
    }
    if (devicesFilter && JSON.parse(devicesFilter).length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'devices='+encodeURIComponent(devicesFilter);
    }
    if (companiesFilter && JSON.parse(companiesFilter).length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'companies='+ encodeURIComponent(companiesFilter);
    }
    if (locationsFilter && JSON.parse(locationsFilter).length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'locations='+encodeURIComponent(locationsFilter);
    }
    if (dateEnabled === 'true' && dateFilter && JSON.parse(dateFilter).length > 0) {
      filters += filters === '' ? '?' : '&'; 
      filters += 'date='+encodeURIComponent(dateFilter);
    }

    setLoading(true);

    const response = await get('devicecartridges' + filters);


    
    if (response.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    //setFilterOptions(response.data.options);
    setData(response.data.data);

    setLoading(false);
  };

  useEffect(()=>{

    fetchData();
  },[searchParams.get('devices'), searchParams.get('cartridgeTypes'), searchParams.get('companies'), searchParams.get('locations'), searchParams.get('date'), searchParams.get('dateEnabled')]);


  return (<><DeviceCartridgesComponent
    loading={loading}
    data={data}
    errorMessage={errorMessage}
    onSetFilters={setFiltersHandler}
    filterValues={filterValues}
  />
  </>);
}