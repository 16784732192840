import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Settings, { Setting } from '../../components/settings';
import { get, post, put } from '../../api';
import { OrderTypeEntry } from '../../components/orders/orderType';
import StockLog from '../../components/stockLog';
import { DeviceCartFilterType, ICartridgeFilterState } from '../../components/common/cartridgesFilter';
import { SelectType } from '../../components/common/types';

export default function StockLogContainer () {

  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async (locations: number[], cartridgeTypes: string[]) => {

    if (locations.length === 0 || cartridgeTypes.length === 0) {

      setData([]);
      return;
    }

    setLoading(true);

    const response = await get('location/' + locations.join(',') + '/stock-cartridges-log/' + cartridgeTypes.join(','));
    
    if (response?.status === 200) {

      setData(response.data);

    } else {

      setError(response.message ?? 'Failed to load stock log');
    }

    setLoading(false);
  
    return;
  };

  
  const filterChangeHandler = async (filters: ICartridgeFilterState) => {

    loadData(filters.locations, filters.cartridgeTypes);
  };


  
  return (<StockLog 
    error={error}
    onSetFilters={filterChangeHandler}
    data={data}
    loading={loading} 
  />);
}