import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import CartridgesFilter, { DatePickerFormat, ICartridgeFilterState } from '../common/cartridgesFilter';

export interface DeviceEventsData {
    action: string,
    slot: string,
    createdTime: string,
    deviceName: string,
    deviceId: string
}



type Props = {
  data: Array<DeviceEventsData>,
  errorMessage?: string,
  loading: boolean,
  deviceSN: string,
  onSetFilters: (filters: ICartridgeFilterState) => void
}

export default function LocationLogContainer ({ deviceSN, loading, data, errorMessage, onSetFilters }: Props) {

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title={'Full Location Log'}
        subTitle="Log of device events, cartridge events and location stock changes"
      />
    </Box>
    <LoadingBar loading={loading} />
    {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
    <CartridgesFilter
      datePickerFormat={DatePickerFormat.DAY}
      showCartridgeTypes={false}
      showDatePickerAwlays={true}
      showDevice={false}
      singleLocation={true}
      singleCustomer={true}
      onSetFilters={onSetFilters}
    />
    <PortalTable 
      headCells={[
        { 
          disablePadding: false,
          id: 'eventtype',
          label: 'Event Type',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'id',
          label: 'Id',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'event',
          label: 'Event',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'slot',
          label: 'Slot',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'tagid',
          label: 'Tag ID',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'cartridge',
          label: 'Cartridge',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'stock',
          label: 'Stock',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'time',
          label: 'Time',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'devicename',
          label: 'Device',
          numeric: false 
        },
      ]}
      data={data}
    />
  </Box>;
}