import { Alert, Box, Container, Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { red, yellow } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { CartrigeType, ICartrigesState } from '../../features/cartriges/cartrigesSlice';
import { WideButton } from '../common/button';
import TextFieldCP from '../common/input/textField';
import { LoadingBar } from '../common/loading';
import H2yoLogo from '../common/logos/h2yo';
import PageHeader from '../common/page/pageHeader';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import FileUpload from '../common/fileUpload';
import { API_URL } from '../../api';
import { ContactRole } from '../../features/user/userSlice';

export const imageExists = async (url: string) => {

  return new Promise((resolve, reject)=>{

    const img = new Image();
    img.onload = function() { resolve(true); };
    img.onerror = function() { resolve(false); };
    img.src = url;
  });
};

type Setting = {
    id: string,
    value_setting: string,
    value_parent?: string,
    value: string,
    description: string
}

export type CompanyData = {
    id: number,
    name: string,
    address: string,
    contactRole?: ContactRole,
    settings: Setting[]
}

export interface ICompanySettingsData {
    data: CompanyData,
    loading: boolean,
    error: string,
    navigate: (url: string) => void,
    onSave: (settingsValues: {[key: string]: string}) => void
}

export const COMPANY_LOGO_URL = API_URL + '/company/logo/[ID].png';

export default function CompanySettingsSingle ({ loading, data, error, navigate, onSave }: ICompanySettingsData) {
    
  const theme = useTheme();

  const [settingsValues, setSettingsValue] = useState<{ [key: string]: string; }>({});
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  
  const onUpdate = (id: string, value: string) => {

    const settingsValuesUpdated = { ...settingsValues };

    settingsValuesUpdated[id] = value;

    setSettingsValue(settingsValuesUpdated);
  };

  useEffect(() => {

    const settingsNew: { [key: string]: string; } = {};
    data.settings.map(x => {

      settingsNew[x.id] = x.value ?? x.value_parent;

      return x;
    });

    setSettingsValue(settingsNew);

  }, [data.settings]);

  const setImage = async () => {

    const src = COMPANY_LOGO_URL.replace('[ID]', data.id.toString());

    const exists = await imageExists(src);

    if (exists) {
      setCompanyLogoUrl(src);
    }
  };

  useEffect(() => {
    setImage();

  }, [data.id]);

  return <Container sx={{ padding: theme.spacing(0, 6) }}>
    <PageHeader
      title={data.name + ' Settings'}
      subTitle={''}
    />
    <LoadingBar loading={loading} />
    <Box>
      {error ? <Box><Alert severity="error">{ error }</Alert></Box> : null}
      <p>{data.address}</p>
    </Box>
    {confirmDelete ? <Box>del</Box> : null}
    {data.contactRole === ContactRole.ACCOUNT_OWNER ?
      <Box sx={{ padding: (theme)=>theme.spacing(2, 0), marginBottom: (theme)=>theme.spacing(4), width: 400 }}>
        <Typography variant="h5" color={theme.palette.primary.dark} maxWidth={355} gutterBottom component="div" align="left">
          Distributor Logo
        </Typography>
        {companyLogoUrl ? <Box sx={{ margin: theme.spacing(1, 0) }}>
          <img 
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src='image_path_here';
            }}
            src={companyLogoUrl} 
          /></Box> : <Box sx={{ margin: theme.spacing(1, 0), width: 180, height: 52, backgroundColor: theme.palette.grey[300] }} />}
        <Box>
          <Typography variant="body1">Image has to be 100x20px and in .png format</Typography>
          <FileUpload
            uploadUrl={API_URL + '/company/logo/' + data.id}
            deleteUrl={API_URL + '/company/logo'}
            onUploaded={()=>{
              const src = COMPANY_LOGO_URL.replace('[ID]', data.id.toString());
              setCompanyLogoUrl(src);
            }}
            onDeleted={()=>setCompanyLogoUrl(null)}
          />
        </Box>
      </Box> : null}
    <Box>
      {data.settings.map((x, i)=>(
        <Grid container sx={{ padding: theme.spacing(0.5, 0) }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={i}>
          <Grid md={3} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="ID" 
              disabled={true}
              variant="outlined"
              value={x.id}
            />
          </Grid>
          <Grid md={2} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="System Setting" 
              variant="outlined"
              disabled={true}
              value={x.value_setting}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>)=>{ onUpdate(x.id, ev.target.value); }}
            />
          </Grid>
          {typeof x.value_parent !== 'undefined' ? <Grid md={2} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              InputLabelProps={{ shrink: true }}
              focused
              fullWidth
              size="small" 
              label="Hod Setting" 
              variant="outlined"
              disabled={true}
              value={x.value_parent}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>)=>{ onUpdate(x.id, ev.target.value); }}
            />
          </Grid> : null}
          <Grid md={2} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small" 
              label="Company Setting" 
              variant="outlined" 
              value={settingsValues[x.id] ?? ''}
              focused
              onChange={(ev: React.ChangeEvent<HTMLInputElement>)=>{ onUpdate(x.id, ev.target.value); }}
            />
          </Grid>
          {/*<Grid md={4} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="Description" 
              variant="outlined" 
              value={x.description}
            />
      </Grid>*/}
        </Grid>
      ))}
      <WideButton onClick={()=>onSave(settingsValues)}>Save</WideButton>
    </Box>

  </Container>
  ;
}