import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { SelectType } from '../types';
import { SelectProps } from '@mui/material';

interface SelectInput extends SelectProps {
  value: any,
  onSelectOption: (data: SelectType) => void,
  label: string,
  selectOptions: Array<SelectType>
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectInput (props: SelectInput) {

  const { selectOptions, label, value, onSelectOption, onChange, readOnly, ...selectProps } = props;

  const optionsIdMap: any = {};
  selectOptions.map((x: SelectType)=>optionsIdMap[x.id] = { id: x.id, name: x.name });

  const changeHandler = (event: any) => {

    onSelectOption(optionsIdMap[event.target.value]);
  };

  return <div>
    <FormControl sx={{ m: 1, width: 300, margin: 0 }} size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        size="small"
        sx={{ maxWidth: '230px' }}
        defaultValue={value?.id}
        value={value?.id ? value.id.toString() : ''}
        onChange={changeHandler}
        MenuProps={MenuProps}
        {...selectProps}
      >
        {selectOptions.map((x: SelectType, i: number) => (
          <MenuItem
            dense={true}
            key={i}
            value={x.id}
            selected={x.id === value.id}
          >
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>;
}