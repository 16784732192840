import { Box } from "@mui/system";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { green, red, yellow, grey } from "@mui/material/colors";
import { DeviceCartridgeData } from "../../features/devices/devicesSlice";
import { LocationStock } from ".";
import BookIcon from "@mui/icons-material/Book";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { CartridgeUsageType } from "../../features/devices/devicesSlice";
import { Setting } from "../settings";

const cartridgeStyle = (theme: any, color: string) => {
  return {
    cursor: "pointer",
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0, 1),
    backgroundColor: color,
    minWidth: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(0.5),
  };
};

type Props = {
  deviceCartridgeData: DeviceCartridgeData;
  inputBoxActive: boolean;
  onUpdateStock: (
    locationId: number,
    cartType: string,
    value: number,
    isIncrease: boolean
  ) => void;
  suggestedOrder: number;
  cartridgeUsage: CartridgeUsageType | null;
  locationId: number;
  cartridgeCount: number;
  deviceId: string;
  loading: boolean;
  inSlot: boolean;
  settings?: Setting[];
};

const handleLogClick = (
  locationId: number,
  cartridgeType: string,
  navigate: NavigateFunction
) => {
  // open in new tab
  window.open(
    "/stock-log/?locations=" +
      encodeURIComponent(JSON.stringify([locationId])) +
      "&cartridgeTypes=" +
      encodeURIComponent(JSON.stringify([cartridgeType])),
    "_blank"
  );
};

export default function CartridgeBox({
  deviceCartridgeData,
  loading,
  inputBoxActive,
  onUpdateStock,
  suggestedOrder,
  inSlot,
  locationId,
  cartridgeCount,
  deviceId,
  cartridgeUsage,
  settings,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const daysLeft: number = cartridgeUsage
    ? (cartridgeCount * cartridgeUsage.volume) / cartridgeUsage.dailyUsage
    : 0;
  const daysOfStock = settings
    ? settings.find((x) => x.id === "DAYS_OF_STOCK")?.value || "20"
    : "20";

  const c = deviceCartridgeData;

  const percent =
    c.volume && c.volume !== 0
      ? Math.round(100 - (c.totalPumped / c.volume) * 100)
      : false;

  let color;

  if (percent > 10) {
    color = green[200];
  } else if (percent > 4) {
    color = yellow[200];
  } else {
    color = red[200];
  }

  if (!percent) {
    color = grey[400];
  }

  //const crtCount = locationsStock[d.locationId]?.[(c.cartridgeType)];

  return (
    <Box
      sx={cartridgeStyle(theme, color)}
      style={
        daysLeft < parseInt(daysOfStock) ? { border: "3px solid red" } : {}
      }
    >
      <Box>
        {inSlot ? (
          <Box
            onClick={() => {
              // open in new tab
              window.open(
                "/cartridge-events/" + c.tagId + "/" + deviceId,
                "_blank"
              );
            }}
          >
            <strong>{c.slot}</strong>
          </Box>
        ) : null}

        <Box
          onClick={() => {
            // open in new tab
            window.open(
              "/cartridge-events/" + c.tagId + "/" + deviceId,
              "_blank"
            );
          }}
        >
          {c.cartridgeType
            ? c.cartridgeName + " " + " (" + c.cartridgeType + ")"
            : ""}
          {inSlot && percent !== false ? ":" + percent + "%" : ""}
        </Box>
      </Box>

      {c.cartridgeType && inputBoxActive ? (
        <Box sx={{ display: "flex" }}>
          <RemoveIcon
            onClick={() => {
              onUpdateStock(
                locationId,
                c.cartridgeType,
                Math.floor(cartridgeCount) - 1,
                false
              );
            }}
          />

          <h2 style={{ margin: "0" }}>{Math.floor(cartridgeCount)}</h2>
          <AddIcon
            onClick={() => {
              onUpdateStock(
                locationId,
                c.cartridgeType,
                Math.floor(cartridgeCount) + 1,
                true
              );
            }}
          />

          <BookIcon
            onClick={() =>
              handleLogClick(locationId, c.cartridgeType, navigate)
            }
          />
        </Box>
      ) : null}
      {loading ? (
        <Box sx={{ height: "12px", paddingTop: "4px" }}>
          <LinearProgress />
        </Box>
      ) : cartridgeUsage ? (
        <>
          <Box>
            Crt. demand:{" "}
            <strong>
              {(
                (cartridgeUsage.dailyUsage * 30) /
                cartridgeUsage.volume
              ).toFixed(2)}
            </strong>
          </Box>
          <Box>
            Days left: <strong>{daysLeft < 0 ? 0 : daysLeft.toFixed(2)}</strong>
          </Box>
        </>
      ) : (
        <>
          <Box>
            Crt. demand: <strong>0.00</strong>
          </Box>
          <Box>
            Days left: <strong>0.00</strong>
          </Box>
        </>
      )}
    </Box>
  );
}
