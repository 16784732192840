import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { get, post } from '../../api';
import SnackBar, { SnackState } from '../../components/common/snackBar';
import { SelectType } from '../../components/common/types';
import Devices, { DeviceDataRow, LocationStock } from '../../components/devices';
import { DevicesFilterProps } from '../../components/devices/filterProps';
import { getDevices, addData, setVisible, getSnackMessage, getSnack, setSnack } from './devicesSlice';
import { Setting } from '../../components/settings';

type FetchDataProps = {
  onlyActive: boolean
}

let timeout: ReturnType<typeof setTimeout>;


export default function DevicesContainer() {

  const data = useSelector(getDevices);
  const snack = useSelector(getSnack);
  const snackMessage = useSelector(getSnackMessage);
  const [settings, setSettings] = useState<Setting[]>([]);

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [deviceRefresh, setDeviceRefresh] = useState<boolean>(false);
  const [allCartridges, setAllCartridges] = useState<SelectType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<DevicesFilterProps>({
    onlyActive: true,
    showHidden: false,
    locations: null,
    devices: null,
    companies: null,
  });

  const refreshCartridgeUsage = async() => {
    setLoading(true);
    await get('stock-usage');
    setLoading(false);
  }

  const loadSettings = async () => {

    const response = await get('settings');

    
    if (response.status === 200) {

      setSettings(response.data);

    }
  
    return;
  };

  const fetchData = async () => {

    setDeviceRefresh(true);
    setLoading(true);


    let locations: number[] = [];
    if (filters.locations && filters.locations.length > 0) {
      locations = filters.locations;
    }

    const urlLocation = searchParams.get('locationId');

    if (urlLocation) {
      locations.push(parseInt(urlLocation));
    }

    let filtersUrl = '';

    if (filters.devices && filters.devices.length > 0) {
      filtersUrl += '&devices='+encodeURIComponent(JSON.stringify(filters.devices));
    }
    if (filters.companies && filters.companies.length > 0) {
      filtersUrl += '&companies='+ encodeURIComponent(JSON.stringify(filters.companies));
    }
    if (locations.length > 0) {
      filtersUrl += '&locations='+encodeURIComponent(JSON.stringify(locations));
    }

    filtersUrl += '&showHidden=' + (filters.showHidden ? 'true' : 'false');

    const response = await get('devices?onlyActive=' + filters.onlyActive + filtersUrl);
    
    setDeviceRefresh(false);
    setLoading(false);
    if (response && response.status !== 200) {

      setErrorMessage(response.message);
      return;
    }

    if (!response) {
      setErrorMessage('Could not fetch devices!');
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    if (response.data) {

      dispatch(addData(response.data ?? { data: [], stockData: {}, suggestedOrderData: {} }));
      setAllCartridges(response.data?.options?.crtridgeTypes ?? []);

      
    //  setLocationsStock(response.data?.locationsStock ?? {});
    //  setLocationsSuggestedOrder(response.data?.locationsSuggestedOrder ?? {});
    }
  };

  const visiblityChangeHandler = async (data: DeviceDataRow) => {

    dispatch(setVisible({ deviceId: data.id, visible: !data.visible }));

    let response;
    if (data.visible) {
      response = await post('manage-device/' + data.id + '/hide', {});
    } else {
      response = await post('manage-device/' + data.id + '/show', {});
    }

    if (response && response.status !== 200) {
      dispatch(setVisible({ deviceId: data.id, visible: data.visible }));
      setErrorMessage(response.message);
      return;
    }

    if (!response) {
      dispatch(setVisible({ deviceId: data.id, visible: data.visible }));
      setErrorMessage('Could not fetch devices!');
    }

    return;
  };


  useEffect(() => {
    loadSettings()
    fetchData();
  }, [filters.onlyActive, searchParams.get('locationId'), filters.companies, filters.locations, filters.showHidden, filters.devices]);

  return <Devices 
    loading={loading}
    snackState={snack}
    snackMessage={snackMessage}
    onCloseSnack={()=>{dispatch(setSnack({ snack: SnackState.HIDDEN, message: '' }));}}
    onVisiblityChange={visiblityChangeHandler}
    allCartridges={allCartridges}
    deviceRefresh={deviceRefresh}
    errorMessage={errorMessage} 
    setFilters={setFilters}
    filters={filters}
    data={data}
    onReload={fetchData}
    onRCU={refreshCartridgeUsage}
    settings={settings}
  />;
}