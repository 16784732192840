import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type CompanyDataEntry = {
    name: string,
    id: number,
    companies: []
}
type ComponentProps = {
    data: Array<CompanyDataEntry>,
  }

export default function ChildCompaniesTable({ data }: ComponentProps) {

  const navigate = useNavigate();

  return <PortalTable
    data={data}
    headCells={[
      {
        disablePadding: false,
        id: 'id',
        label: 'Id',
        numeric: true  
      },
      {
        disablePadding: false,
        id: 'name',
        label: 'Name',
        numeric: false
      },
      { 
        disablePadding: false,
        id: 'actions',
        label: 'Actions',
        numeric: false,
        customCell: (cell: CompanyDataEntry)=><Box>
          <BuildCircleIcon sx={{ cursor: 'pointer' }} onClick={()=>{ navigate('/company-settings/' + cell.id); }} />
        </Box>  
      },
    ]} />;
}