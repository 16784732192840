import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { SnackState } from '../../components/common/snackBar';

export type DeviceCartridgeData = {
  cartridgeType: string,
  cartridgeName: string,
  isActive: boolean,
  tagId: string,
  slot: string,
  totalPumped: number,
  volume: number,
  lastDispensed: string,
  locationStock?: number
}

export interface DeviceData {
  id: string,
  SN: string,
  name: string,
  ownerId: number,
  ownerName: string,
  customerId: number,
  customerName: string,
  locationId: number,
  locationName: string,
  dateCreated: string,
  lastDispensed: string,
  status: string,
  cartridges: DeviceCartridgeData[],
  visible: boolean,
  dashboard_url?: string
}

export interface CartridgeUsageType {
  cartridgeType: string,
  totalVolumeDispensed: number,
  dayCount: number,
  volume: number,
  dailyUsage: number
}

export interface IDevicesState {
    data: Array<DeviceData>
    stockData: {[locationId: number]: {[cartType: string]: number}},
    suggestedOrderData: {[locationId: number]: {[cartType: string]: number}},
    allCartridgeUsages: {[locationId: number]: {[cartType: string]: CartridgeUsageType}},
    loading: boolean,
    snackMessage: string,
    snack: SnackState
  }

const initialState: IDevicesState = {
  data: [
  ],
  stockData: {},
  suggestedOrderData: {},
  allCartridgeUsages: {},
  loading: false,
  snackMessage: '',
  snack: SnackState.HIDDEN,
};

type PayloadType = {
  data: Array<DeviceData>
  locationsStock: {[locationId: number]: {[cartType: string]: number}},
  locationsSuggestedOrder: {[locationId: number]: {[cartType: string]: number}}
  allCartridgeUsages: {[locationId: number]: {[cartType: string]: CartridgeUsageType}}
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addData: (state, action: PayloadAction<PayloadType>) => {

      state.data = action.payload.data;
      state.stockData = action.payload.locationsStock;
      state.suggestedOrderData = action.payload.locationsSuggestedOrder;
      state.allCartridgeUsages = action.payload.allCartridgeUsages;
    },
    setStock: (state, action: PayloadAction<{locationId: number, cartType: string, value: number}>) => {

      const payload = action.payload;
      state.stockData[payload?.locationId][payload?.cartType] = payload.value;
     
    },
    setSuggestedOrder: (state, action: PayloadAction<{locationId: number, cartType: string, value: number}>) => {

      const payload = action.payload;
      state.suggestedOrderData[payload?.locationId][payload?.cartType] = payload.value;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {

      state.loading = action.payload;
    },
    setSnack: (state, action: PayloadAction<{message?: string, snack: SnackState}>) => {

      if (action.payload.message) {
        state.snackMessage = action.payload.message;
      }
      
      state.snack = action.payload.snack;
    },
    setVisible: (state, action: PayloadAction<{deviceId: string, visible: boolean}>) => {

      const deviceId = action.payload.deviceId;
      const visible = action.payload.visible;

      const data = state.data.map(d=>{

        if (d.id === deviceId) {
          d.visible = visible;
        }

        return d;
      });

      state.data = [...data];
    }
  },
});

export const getDevices = (state: RootState) => state.devices.data;
export const getStock = (state: RootState) => state.devices.stockData;
export const getSuggestedOrders = (state: RootState) => state.devices.suggestedOrderData;
export const getAllCartridgeUsages = (state: RootState) => state.devices.allCartridgeUsages;
export const getSnack = (state: RootState) => state.devices.snack;
export const getSnackMessage = (state: RootState) => state.devices.snackMessage;
export const getLoading = (state: RootState) => state.devices.loading;

export const { addData, setVisible, setSuggestedOrder, setStock, setLoading, setSnack } = devicesSlice.actions;

export default devicesSlice.reducer;
