import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Settings, { Setting } from '../../components/settings';
import { get, post, put } from '../../api';
import { OrderTypeEntry } from '../../components/orders/orderType';
import StatsDashboard from '../../components/statsDashboard';
import Customers, { ICustomersDataEntry } from '../../components/customers';
import { useNavigate } from 'react-router-dom';
import { fetchCountriesOptions, isLoaded, selectData } from './countriesSlice';
import SelectInput from '../../components/common/input/select';
import { SelectType } from '../../components/common/types';

type SelectContainerProps = {
    label: string,
    onChange: (id: number) => void,
    value: number | string,
    error?: boolean,
    disabled?: boolean
    fullWidth?: boolean
}

export default function SelectCountry ({ label, onChange, value, disabled, error, fullWidth }: SelectContainerProps) {

  const data = useSelector(selectData);
  const isDataLoaded: boolean = useSelector(isLoaded);

  const dispatch = useDispatch();

  useEffect(()=>{

    if (!isDataLoaded) {
      dispatch(fetchCountriesOptions());
    }
  }, [isDataLoaded]);

  const SelectInputHandler = (option: SelectType) => {

    if (typeof option.id === 'number') {
      onChange(option.id);
    } else {
      onChange(parseInt(option.id));
    }
    
  };

  
  return (<SelectInput
    error={error}
    disabled={disabled}
    label={label}
    selectOptions={data}
    value={{ id: value }}
    fullWidth={fullWidth}
    onSelectOption={SelectInputHandler}
  />);
}