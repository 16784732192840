import * as React from 'react';

type Props = {
  height?: number,
  width?: number
}

export default function H2yoLogo ({ width, height }: Props = { width: 67, height: 17 }) {
  return <svg width={width} height={height} viewBox="0 0 67 17" fill="none">
    <path d="M0 0.549438H5.3383V6.28027H10.5981V0.549438H15.8972V16.4466H10.5981V10.5195H5.3383V16.4466H0V0.549438Z" fill="#0D8F68" />
    <path d="M31.8732 12.7962V16.4074H19.2732V13.5813L25.3181 7.92896C25.9068 7.37942 26.2994 6.9084 26.4956 6.51587C26.6919 6.12335 26.8096 5.73083 26.8096 5.33831C26.8096 4.82803 26.6134 4.39625 26.2601 4.12148C25.9068 3.84672 25.3966 3.68971 24.7293 3.68971C24.1405 3.68971 23.591 3.80746 23.0807 4.08223C22.5704 4.357 22.1779 4.74952 21.8246 5.2598L18.4097 3.37569C19.0377 2.31588 19.9405 1.49159 21.0788 0.902809C22.2171 0.314025 23.5517 0 25.1218 0C26.3386 0 27.4377 0.196261 28.3797 0.588784C29.3218 0.981306 30.0676 1.57009 30.6171 2.31588C31.1666 3.06167 31.4022 3.92522 31.4022 4.90653C31.4022 5.77008 31.2059 6.63363 30.8526 7.37943C30.4601 8.16447 29.7536 9.02801 28.6545 10.0093L25.5928 12.8355H31.8732V12.7962Z" fill="#0D8F68" />
    <path d="M41.1758 16.4466H35.4843L38.9384 11.1083L32.5796 0.549438H38.1927L41.7646 6.47653L45.3366 0.549438H50.6356L41.1758 16.4466Z" fill="#0D8F68" />
    <path d="M53.7755 15.7401C52.4802 15.0336 51.4596 14.0523 50.7138 12.757C49.968 11.5009 49.6147 10.0878 49.6147 8.47846C49.6147 6.90837 49.968 5.49529 50.7138 4.19997C51.4596 2.9439 52.4802 1.96259 53.7755 1.2168C55.0708 0.510257 56.5231 0.156982 58.1325 0.156982C59.7418 0.156982 61.1942 0.510257 62.4895 1.2168C63.7848 1.92334 64.8054 2.90465 65.5512 4.19997C66.2969 5.45604 66.6502 6.86912 66.6502 8.47846C66.6502 10.0485 66.2969 11.4616 65.5512 12.757C64.8054 14.013 63.7848 14.9943 62.4895 15.7401C61.1942 16.4467 59.7418 16.7999 58.1325 16.7999C56.5231 16.7999 55.0708 16.4467 53.7755 15.7401ZM60.6446 11.4224C61.3119 10.6766 61.6259 9.69528 61.6259 8.47846C61.6259 7.30089 61.3119 6.31959 60.6446 5.53454C59.9773 4.78875 59.153 4.39623 58.1325 4.39623C57.1119 4.39623 56.2484 4.78875 55.5811 5.53454C54.9138 6.28034 54.5998 7.26164 54.5998 8.47846C54.5998 9.65603 54.9138 10.6373 55.5811 11.4224C56.2484 12.1682 57.0727 12.5607 58.1325 12.5607C59.153 12.5607 59.9773 12.1682 60.6446 11.4224Z" fill="#0D8F68" />
  </svg>;
}