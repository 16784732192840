import React, { useEffect, useState } from 'react';
import { post, get } from '../../api';
import Login, { ILoginCompState } from '../../components/account/login';

import {
  BrowserRouter as Router,
  useNavigate,
} from 'react-router-dom';
import { addData, addOdooData, ContactRole, selectData } from '../user/userSlice';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Invite, { FormState, IInviteCompState, IInviteRole } from '../../components/invite/form';

const InviteContainer = ({ inviteCompany, onClose }: {inviteCompany?: boolean, onClose?: () => void} = { inviteCompany: false }) => {

  const [inviteError, setInviteError] = useState('');
  const [message, setMessage] = useState('');
  const [accountOwners, setAccountOwners] = useState([]);
  const [inviteRoles, setInviteRoles] = useState<IInviteRole[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<FormState>(FormState.INPUT);

  const userData: any = useSelector(selectData);

  useEffect(() => {
    
    if (userData.role === ContactRole.CUSTOMER_USER) {
      setInviteRoles([
        { role: ContactRole.CUSTOMER_USER, title: 'Account Owner Manager' },
        { role: ContactRole.LOCATION_USER, title: 'Location Manager' },
      ]);
    } else if (userData.role === ContactRole.ACCOUNT_OWNER_MNG && !inviteCompany) {
      setInviteRoles([
        { role: ContactRole.ACCOUNT_OWNER_MNG, title: 'Account Owner Manager' },
        { role: ContactRole.ACCOUNT_OWNER_USER, title: 'Account Owner User' },
      ]);
    }

    if (userData.role !== ContactRole.ADMIN) {
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      
      const response = await get('users/odooaccountowners');
      setAccountOwners(response.data);
    };
    
    setLoading(false);

    fetchData().catch(console.error);

  }, []);

  const handleInvite = async ({ email, accountOwnerOdoo, userRole }: IInviteCompState) => {

    //const role = ContactRole.ACCOUNT_OWNER_MNG;

    const role = userData.role;

    let inviteUserRole;
    let parentId = 0;
    switch (role) {
      case ContactRole.ADMIN: // admin invites hod level user
        inviteUserRole = ContactRole.ACCOUNT_OWNER_MNG;
        parentId = accountOwnerOdoo;
        break;
      case ContactRole.ACCOUNT_OWNER_MNG:
      case ContactRole.ACCOUNT_OWNER_USER: //hod level user invites company

        if (inviteCompany) {
          inviteUserRole = ContactRole.CUSTOMER_USER;
          accountOwnerOdoo = userData.companyId;
        } else {
          inviteUserRole = userRole;
          accountOwnerOdoo = userData.ownerId;
          parentId = userData.companyId;
        }

        break;
      case ContactRole.CUSTOMER_USER:
        inviteUserRole = userRole;
        accountOwnerOdoo = userData.ownerId;
        parentId = userData.companyId;
        break;
      case ContactRole.LOCATION_USER:
        inviteUserRole = ContactRole.LOCATION_USER;
        accountOwnerOdoo = userData.ownerId;
        parentId = userData.companyId;
        break;
    }

    setLoading(true);

    const response = await post('invite/user', {
      email: email,
      role: inviteUserRole,
      accountOwner: accountOwnerOdoo,
      parentId: parentId,
      inviteError: inviteError
    });

    if (response.result) {
      setFormStatus(FormState.SENT);
      setMessage('Invite sent!');

      if (inviteError !== '') {
        setInviteError('');
      }
      
    } else {
      setInviteError(response.message ?? 'Failed to invite!');
    }

    setLoading(false);

    return;
  };

  return (<>
    <Invite
      onClose={onClose}
      formStatus={formStatus}
      loading={loading}
      onInvite={handleInvite} 
      inviteError={inviteError} 
      inviteRoles={inviteRoles}
      message={message}
      accountOwners={accountOwners} 
    />
  </>);
};

export default InviteContainer;