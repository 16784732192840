import React, { useState } from "react";
import { DeviceData, getDevices } from "../../features/devices/devicesSlice";
import { Box } from "@mui/system";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import PortalTable, { HeadCell } from "../common/table/portalTable";
import { WideButton } from "../common/button";
import { DevicesFilterProps } from "./filterProps";
import { green, red, yellow, grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { SelectType } from "../common/types";
import CartridgesFilter, {
  DeviceCartFilterType,
} from "../common/cartridgesFilter";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackBar, { SnackState } from "../common/snackBar";
import BookIcon from "@mui/icons-material/Book";
import { LoadingBar } from "../common/loading";
import TopBar from "./topBar";
import { useSelector } from "react-redux";
import { DeviceDataRow, LocationStock } from ".";
import CartridgeDevicesContainer from "../../features/devices/cartridgeContainer";
import { Setting } from "../settings";

type Props = {
  onVisiblityChange: (data: DeviceDataRow) => void;
  filters: DevicesFilterProps;
  allCartridges: SelectType[];
  data: DeviceData[];
  settings: Setting[];
};

export default function DevicesTable({
  onVisiblityChange,
  filters,
  allCartridges,
  data,
  settings,
}: Props) {
  const navigate = useNavigate();

  const cellClickHandler = (cell: HeadCell, value: any) => {
    if (cell.id === "locationId") {
      navigate("/orders-debug/" + value);
    }
    if (cell.id === "SN") {
      navigate("/devices-events/" + value);
    }
  };

  const handleLogClick = (locationId: number, cartridgeType: string) => {
    navigate(
      "/stock-log/?locations=" +
        encodeURIComponent(JSON.stringify([locationId])) +
        "&cartridgeTypes=" +
        encodeURIComponent(JSON.stringify([cartridgeType]))
    );
  };

  if (!filters.showHidden) {
    data = data.filter((x) => x.visible);
  }

  const tableData: DeviceDataRow[] = data.map((d) => {
    if (d.cartridges.length === 0) {
      return { ...d, ...{ subRow: <></> } };
    }

    const cartridgeTypePresent: string[] = [];

    const subRow = (
      <Box sx={{ fontSize: "0.80em", display: "flex", maxWidth: 800 }}>
        {d.cartridges.map((c, i) => {
          const percent = Math.round(100 - (c.totalPumped / c.volume) * 100);

          let color;

          if (percent > 10) {
            color = green[200];
          } else if (percent > 4) {
            color = yellow[200];
          } else {
            color = red[200];
          }

          if (!percent) {
            color = grey[400];
          }

          if (c.cartridgeType) {
            cartridgeTypePresent.push(c.cartridgeType);
          }

          return (
            <CartridgeDevicesContainer
              inSlot={true}
              key={i}
              deviceCartridgeData={c}
              locationId={d.locationId}
              deviceId={d.id}
              settings={settings}
            />
          );

          /*  return (<Box
        sx={cartridgeStyle(theme, color)}
        key={i}>
        <Box>
          <Box onClick={()=>{navigate('/cartridge-events/' + c.tagId + '/' + d.id);}} >
            <strong>{c.slot}</strong>
          </Box>
          <Box onClick={()=>{navigate('/cartridge-events/' + c.tagId + '/' + d.id);}} >
            {c.cartridgeType ? c.cartridgeName + ' (' + c.cartridgeType + '):  ' + percent + '%' : ''}
          </Box>
        </Box>
        {c.cartridgeType && locationFilterActive? <Box sx={{ display: 'flex' }}>
          <input onChange={(ev) => {

            const value = ev.target.value;

            if (!isNaN( +value ) && value !== null && value !== '') {
            //  onUpdateStock(d.locationId, c.cartridgeType, value);
            }
          
          }} style={{ width: '50px' }} type="number" value={crtCount} />
          <BookIcon onClick={()=>handleLogClick(d.locationId, c.cartridgeType)} />
        </Box> : null}
        {locationsSuggestedOrder?.[d.locationId]?.[c.cartridgeType] ? <Box>Suggested Order: <strong>{locationsSuggestedOrder?.[d.locationId]?.[c.cartridgeType]}</strong></Box> : <Box>Suggested Order:  <strong>0</strong></Box>}
      </Box>);*/
        })}
        {allCartridges
          .filter((c) => !cartridgeTypePresent.includes(c.id as string))
          .map((c, i) => {
            if (c.isDisabled) {
              return <></>;
            }

            return (
              <CartridgeDevicesContainer
                inSlot={false}
                key={i}
                deviceCartridgeData={{
                  cartridgeType: c.id as string,
                  cartridgeName: c.name,
                  isActive: true,
                  tagId: "",
                  slot: "",
                  totalPumped: 1,
                  volume: 1,
                  lastDispensed: "",
                }}
                locationId={d.locationId}
                deviceId={d.id}
              />
            );

            /* return <Box
        sx={cartridgeStyle(theme, '#bbc6ef')}
        key={i}>
        <Box>
          {c.name + ' (' + c.id + ')'}
        </Box>
        {locationFilterActive ? <Box sx={{ display: 'flex' }}>
          <input onChange={(ev)=>
            onUpdateStock(d.locationId, c.id as string, ev.target.value)
          } style={{ width: '50px' }} type="number" value={crtCount} />
          <BookIcon onClick={()=>handleLogClick(d.locationId, c.id as string)} />
        </Box> : null}
        {locationsSuggestedOrder?.[d.locationId]?.[c.id] ? <Box>Suggested Order:  <strong>{locationsSuggestedOrder?.[d.locationId]?.[c.id]}</strong></Box> : <Box>Suggested Order:  <strong>0</strong></Box>}
      </Box>;*/
          })}
      </Box>
    );
    return { ...d, ...{ subRow: subRow } };
  });

  return (
    <PortalTable
      defaultOrderBy="SN" // locationId
      settings={settings}
      headCells={[
        {
          disablePadding: false,
          id: "visible",
          label: "Vis",
          numeric: false,
          width: "30px",
          customCell: (row: any) => {
            return (
              <>
                <Checkbox
                  onChange={() => onVisiblityChange(row)}
                  checked={row.visible}
                />
              </>
            );
          },
        },
        {
          disablePadding: false,
          id: "SN",
          label: "SN",
          numeric: false,
          cellClickable: true,
          style: { fontSize: "13px", fontWeight: 600, whiteSpace: "nowrap" },
          width: "100px",
        },
        {
          disablePadding: false,
          id: "customerName",
          label: "Customer",
          style: { fontSize: "16px", fontWeight: 600, whiteSpace: "nowrap" },
          width: "180px",
          numeric: false,
        },
        {
          disablePadding: true,
          id: "dashboard_url",
          label: "Order",
          style: { fontSize: "13px", fontWeight: 600, whiteSpace: "nowrap" },
          width: "100%",
          numeric: false,
          cellClickable: true,
          customCell: (row: any) => {
            return (
              <Button
                onClick={() => {
                  window.open(
                    "/orders-debug?" +
                      `companies=%5B${row.customerId}%5D&locations=%5B${row.locationId}%5D`,
                    "_blank"
                  );
                }}
                variant="outlined"
              >
                Order
              </Button>
            );
          },
        },
        {
          disablePadding: true,
          id: "dashboard_url",
          label: "Statistics",
          style: { fontSize: "13px", fontWeight: 600, whiteSpace: "nowrap" },
          width: "100%",
          numeric: false,
          cellClickable: true,
          customCell: (row: any) => {
            console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTT: ",row)
            return (
              <Button
                onClick={() => {
                  window.open(row.statUrl, "_blank");
                }}
                variant="outlined"
              >
                Open Stats
              </Button>
            );
          },
        },
        {
          disablePadding: false,
          id: "name",
          label: "Device name",
          numeric: false,
          style: { fontSize: "13px", fontWeight: 400 },
          width: "160px",
        },
        {
          disablePadding: false,
          id: "locationName",
          label: "Location",
          numeric: false,
          style: { fontSize: "13px", fontWeight: 400 },
          width: "160px",
        },
        {
          disablePadding: false,
          id: "customerId",
          label: "C:ID",
          style: { fontSize: "16px", fontWeight: 400 },
          width: "40px",
          numeric: true,
        },
        {
          disablePadding: false,
          id: "locationId",
          label: "L:ID",
          //style: {color: "#F5F5F5 !important"},
          style: { fontSize: "16px", fontWeight: 600 },
          width: "40px",
          numeric: true,
          cellClickable: true,
        },
        {
          disablePadding: false,
          id: "dateCreated",
          label: "Date Created",
          style: { fontSize: "13px", fontWeight: 400 },
          numeric: false,
        },
        {
          disablePadding: false,
          id: "lastDispensed",
          label: "Last Dispense",
          style: { fontSize: "13px", fontWeight: 600 },
          numeric: false,
        },
        // {
        //   disablePadding: false,
        //   id: 'deployedStatus',
        //   label: 'Deployed',
        //   numeric: false
        // },
        {
          disablePadding: false,
          id: "id",
          label: "Device:ID",
          numeric: false,
          style: { fontSize: "10px" },
        },

        // {
        //   disablePadding: false,
        //   id: 'ownerId',
        //   label: 'HOD:ID',
        //   width: '40px',
        //   numeric: true
        // },

        {
          disablePadding: false,
          id: "ownerName",
          label: "Distributor",
          style: { fontSize: "10px" },
          width: "60px",
          numeric: false,
        },
        {
          disablePadding: false,
          id: "status",
          label: "Status",
          style: { fontSize: "10px" },
          width: "60px",
          numeric: false,
        },
      ]}
      data={tableData}
      onCellClick={cellClickHandler}
    />
  );
}
