import React, { useState } from 'react';
import { Box } from '@mui/system';
import PageHeader from '../common/page/pageHeader';
import { LoadingBar } from '../common/loading';
import CartridgesFilter, { DeviceCartFilterType, ICartridgeFilterState } from '../common/cartridgesFilter';
import PortalTable from '../common/table/portalTable';
import { Alert } from '@mui/material';
import { SelectType } from '../common/types';

type Props = {
    data: Array<{}>,
    onSetFilters: (filters: ICartridgeFilterState) => void,
    loading: boolean,
    error: string
}

const headCells = [
  { 
    disablePadding: false,
    id: 'id',
    label: 'Id',
    numeric: false  
  },
  { 
    disablePadding: false,
    id: 'cartridgeName',
    label: 'Cartridge',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'location',
    label: 'Location',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'cartrigeCount',
    label: 'Count',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'createdTime',
    label: 'Created Time',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'updatedTime',
    label: 'Updated Time',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'inputType',
    label: 'Update Type',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'inputUserEmail',
    label: 'User',
    numeric: false  
  },
  {
    disablePadding: false,
    id: 'changeData',
    label: 'Log Data',
    numeric: false  
  },
];

export default function StockLog ({ data, loading, onSetFilters, error }: Props) {

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title="Stock Log"
        subTitle=""
      />
    </Box>
    <LoadingBar loading={loading} />
    {error ? <Box><Alert severity="error">{error}</Alert></Box> : null}
    <CartridgesFilter
      onSetFilters={onSetFilters}
      showDevice={false}
    />
    <PortalTable
      headCells={headCells}
      data={data}
    />
  </Box>;
}