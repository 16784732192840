import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CartridgeTypeData, CartridgeTypesRow } from "./types";
import { Box } from "@mui/system";
import { formatFromTimestamp } from "../common/text/format";
import { Input, Link, TextField, useTheme } from "@mui/material";
import { grey, green } from "@mui/material/colors";

type RenderAccumDataType = {
  data: CartridgeTypeData;
  rowIndx: Number;
  orderValue: Number;
  onOrderValueChange: (cartridgeId: string, stockCount: number) => void;
  onStockUpdate: (cartridgeTypeId: string, stockCount: number) => void;
  onDeviceClick: (id: string) => void;
};

const ShowMode = ({ children }: { children: JSX.Element }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Box>
      <Box>{showMore ? children : null}</Box>
      <Link
        sx={{ fontSize: "0.8em" }}
        href="#"
        onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          setShowMore(!showMore);
        }}
      >
        {showMore ? "Show Less" : "Show More"}
      </Link>
    </Box>
  );
};

const RenderCartridgeRow = ({
  data,
  key,
}: {
  data: CartridgeTypesRow;
  key: number;
}) => (
  <Box
    sx={{
      fontSize: "0.8em",
      backgroundColor: data.isactive ? green[200] : grey[200],
    }}
    key={key}
  >
    {data.tagid}: {Math.round(data.totalpumped)}/{data.volume}ml {data.slot}
  </Box>
);

export default function RenderAccumData({
  onOrderValueChange,
  onDeviceClick,
  data,
  orderValue,
  rowIndx,
  onStockUpdate,
}: RenderAccumDataType) {
  const theme = useTheme();

  const [requiredValue, setRequiredValue] = useState(
    orderValue ? orderValue : data?.qtyToOrder ? data?.qtyToOrder.toFixed(2) : 0
  );

  useEffect(() => {
    const suggestedOrder =(()=>{
      if(orderValue){
        return orderValue
      } else if(data?.qtyToOrder){
        if(data.qtyToOrder > 0){
          return Math.ceil(data.qtyToOrder)
        }else{
          return 0
        }
      } else {
        return 0
      }
          })()

    // const suggestedOrder = orderValue
    //           ? orderValue
    //           : data?.qtyToOrder
    //           ? data.qtyToOrder > 0
    //             ? Math.ceil(data.qtyToOrder)
    //             : 0
    //           : 0;

       //onOrderValueChange(data.cartridgeId, suggestedOrder as number);

    if (suggestedOrder != 0) {
      console.log("GGGGGGGGGGGGGGGG: ", {
        suggestedOrder,
        cartridgeId: data.cartridgeId,
      });
      onOrderValueChange(data.cartridgeId, suggestedOrder as number);
    } else {
      console.log("VVVVVVVVVVVVVVVVV: ", {
        suggestedOrder,
        cartridgeId: data.cartridgeId,
      });
      onOrderValueChange(data.cartridgeId, 0);
    }

}, [data?.qtyToOrder]);

  const subTableCellStyle = {
    fontSize: "0.75em",
    textAlign: "end",
    padding: theme.spacing(0.25),
  };

  return (
    <TableRow
      key={data.cartridgeId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {data.cartridgeName}
        <br />({data.cartridgeId}) {data.volume} ml
      </TableCell>
      <TableCell>
        {data.willLastThisManyDays < 0
          ? 0
          : data.willLastThisManyDays.toFixed(2)}
      </TableCell>
      {/* <TableCell sx={{ padding: theme.spacing(0.25) }}>
      {data.devices.map((x, i) => (
        <Table key={i}>
          <TableHead><TableCell sx={subTableCellStyle}>id</TableCell><TableCell sx={subTableCellStyle}>daysUsed</TableCell><TableCell sx={subTableCellStyle}>totalPumped</TableCell><TableCell sx={subTableCellStyle}>cartridgesUsed</TableCell></TableHead>
          <TableRow><TableCell sx={subTableCellStyle}><Link href="#" onClick={(ev: React.MouseEvent)=> {ev.preventDefault(); onDeviceClick(x.id);}}>{x.id.substring(x.id.length-5, x.id.length)}</Link></TableCell><TableCell sx={subTableCellStyle}>{x.daysUsed}</TableCell><TableCell sx={subTableCellStyle}>{x.totalPumped.toFixed(5)}</TableCell><TableCell sx={subTableCellStyle}>{x.cartridgesUsed}</TableCell></TableRow>
        </Table>
      ))}
    </TableCell> */}
      <TableCell>{data.avgDemand.toFixed(0)}</TableCell>
      <TableCell>{(data.avgDemand / data.volume).toFixed(2)}</TableCell>
      {/*<TableCell sx={{ width: '200px' }}>{data.allCartridgesData.slice(0, 3).map((x: CartridgeTypesRow, i: number)=>(
      <RenderCartridgeRow data={x} key={i} />))}
    {data.allCartridgesData.length > 0 ?
      <ShowMode>
        <>{data.allCartridgesData
          .slice(3, data.allCartridgesData.length-1)
          .map((x: CartridgeTypesRow, i: number)=>(<RenderCartridgeRow data={x} key={i} />))}</>
      </ShowMode> : null  
    }
  </TableCell>*/}
      {/* <TableCell>{data.currentStock}</TableCell> */}
      {/* <TableCell>{formatFromTimestamp(data.cartridgeUpdatedTime)}</TableCell> */}
      {/* <TableCell>
      <Box sx={{ display: 'flex' }}>{data.ordersInProgress.map((x, i)=>{
        return (<Box key={i} sx={{ fontSize: '0.9em', width: '80px' }}><Box>{rowIndx === 0 ? formatFromTimestamp(x.createdTime) : null}</Box><Box><strong>count:</strong> {x.cartridgeCount ? <span>{x.cartridgeCount} <br />(AD: {x.cartridgeAvgDemand?.toFixed(5)})</span> : null}</Box></Box>);
      })}</Box>
    </TableCell> */}
      <TableCell>
        {(() => {
          if (data?.qtyToOrder) {
            if (data.qtyToOrder > 0) {
              return Math.ceil(data.qtyToOrder);
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        })()}
        {/* {data?.qtyToOrder ? data?.qtyToOrder.toFixed(2) : 0} */}
      </TableCell>
      <TableCell>
        <Input
          defaultValue={(() => {
            if (data?.qtyToOrder) {
              if (data.qtyToOrder > 0) {
                return Math.ceil(data.qtyToOrder);
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          })()}
          //defaultValue={data?.qtyToOrder ? data?.qtyToOrder.toFixed(2) : 0}
          value={(() => {
            return orderValue || 0;
            // return orderValue
            //   ? orderValue
            //   : data?.qtyToOrder
            //   ? data.qtyToOrder > 0
            //     ? Math.ceil(data.qtyToOrder)
            //     : 0
            //   : 0;
          })()}
          onChange={(ev) => {
            console.log("XXXXXXXXXXXXXXXXX: ", ev.target.value);
            if (parseInt(ev.target.value) <= 0) {
              onOrderValueChange(data.cartridgeId, 0);
              return;
            }
            onOrderValueChange(data.cartridgeId, parseInt(ev.target.value));
          }}
          size="small"
          type="number"
          sx={{ width: "50px" }}
          inputMode="numeric"
        />
      </TableCell>
    </TableRow>
  );
}
