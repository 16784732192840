import { Theme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState, useRef } from 'react';
import { WideButton } from '../button';

type CompProps = {
  uploadUrl: string,
  deleteUrl?: string,
  onUploaded?: () => void
  onDeleted?: () => void
};

export default function FileUpload({ uploadUrl, deleteUrl, onUploaded, onDeleted }: CompProps) {

  const addNewImageHandler = () => {

    const fileInput = document.getElementById('fileid') as HTMLInputElement;

    if (fileInput) {

      fileInput.click();
      fileInput.onchange = function() {
    
        if (!fileInput.files) {
          return;
        }

        const formData = new FormData();
        //    formData.append('name', name.value);
        for (let i =0; i < fileInput.files.length; i++) {
          formData.append('file', fileInput.files[i]);
        }
        fetch(uploadUrl, {
          //    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          method: 'post',
          credentials: 'include',
          headers: {
            //    'Accept': 'application/json',
            //    'Content-Type': 'application/json',
            //    'mode': 'cors',
          },
          body: formData
        }).then((res) => {

          if (onUploaded) {
            onUploaded();
          }
          
        }).catch((err) => console.log('Error occured', err));
      };
    }
  };

  const deleteImageHandler = () => {
    if (deleteUrl) {
      fetch(deleteUrl, {
        //    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'delete',
        credentials: 'include',
        headers: {
          //    'Accept': 'application/json',
          //    'Content-Type': 'application/json',
          //    'mode': 'cors',
        },
      }).then((res) => {

        if (onDeleted) {
          onDeleted();
        }

        return;
      }).catch((err) => console.log('Error occured', err));
    }

  };

  const handleFileUploadError = (error: any) => {
    // Do something...
  };
  
  const handleFilesChange = (files: File[]) => {
    // Do something...
  };

  return <Box>
    <form id="formid" method="POST" encType="multipart/form-data">
      <input id="fileid" type="file" accept="image/png" hidden />
    </form>
    <Box display="flex">
      <Box sx={{ margin: (theme: any)=>theme.spacing(0, 2, 0, 0) }}><WideButton onClick={addNewImageHandler}>Upload</WideButton></Box>
      <Box>{deleteUrl ? <WideButton onClick={deleteImageHandler}>Delete</WideButton> : null }</Box>
    </Box>
  </Box>;
} 