import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { get, post, put } from '../../api';
import { SelectType } from '../../components/common/types';
import Devices from '../../components/devices';
import { DevicesFilterProps } from '../../components/devices/filterProps';
import DeviceEventsComponent, { DeviceEventsData } from '../../components/devicesEvents';
import DevicesHods, { DevicesHodEntry } from '../../components/devicesHods';

export default function DevicesEventsContainer() {

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { deviceSN } = useParams();

  const [data, setData] = useState<DeviceEventsData[]>([]);
  
  const fetchData = async () => {

    setLoading(true);
    
    const response = await get('device/' + deviceSN + '/events');

    setLoading(false);

    if (response.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    setData(response.data);
  };

  useEffect(()=>{

    fetchData();
  }, [deviceSN]);

  return <DeviceEventsComponent
    deviceSN={deviceSN ?? ''}
    loading={loading}
    errorMessage={errorMessage}
    data={data}
  />;
}