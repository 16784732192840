import React, { MouseEvent, useEffect, useState } from 'react';
import { get, post, put } from '../../api';
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { LocationFormType } from '../../components/locations/location';
import { Alert, Box, Container, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CompanyDetails from '../../components/common/company/details';
import CompanyBilling from '../../components/common/company/billing';
import { LoadingBar } from '../../components/common/loading';


const EditClientContainer = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<LocationFormType>({
    name: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
    country: '',
    country_id: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { clientId, locationId } = useParams();

  const loadDataHandler = async () => {

    setLoading(true);
    const response = await get('edit-company/' + clientId).catch(console.error);
    setLoading(false);

    console.log('response', response)
    if (response?.status === 200) {
      
      setError('');
      setData(response.data);
    } else {
      setError('Failed to update location data');
    }

    return;
  };

  const onSubmitEditHandler = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    const response = await put('edit-company/' + clientId, data).catch(console.error);
    setLoading(false);
    if (response?.status === 200) {
      setError('');
      navigate('/clients');
      //setData(response.data.data);
    } else {
      setError('Failed to update location data');
    }

    return;
  };

  useEffect(()=>{

    loadDataHandler();
  },[]);

  return (<>
        {loading ? <LoadingBar loading={loading} /> :
        <Container
          maxWidth="sm">
      <FormControl>
        {error ? <Box sx={{ padding: (theme)=>theme.spacing(1, 0) }}><Alert severity="error">{error}</Alert></Box> : null}
        
          <Box sx={{ margin: (theme)=>theme.spacing(1, 0) }}>
            <Box sx={{ padding: (theme)=>theme.spacing(1, 0) }}>
              <Typography 
                variant="h6"
                gutterBottom
                component="div"
                align="left"
              >
                Company details
              </Typography>
              <CompanyDetails
                errorFields={[]}
                disabledProps={[]}
                data={data}
                onSetData={setData}
              />
            </Box>
            <Box sx={{ padding: (theme)=>theme.spacing(1, 0) }}>
              <Typography variant="h6" gutterBottom component="div" align="left">Billing contact</Typography>
              <CompanyBilling data={data} onSetData={setData} disabledProps={[]} />
            </Box>
            <LoadingButton
              sx={{ margin: (theme)=>theme.spacing(1, 0) }}
              type="submit"
              size="small"
              loading={false}
              variant="contained" 
              onClick={onSubmitEditHandler}
            >
              Save
            </LoadingButton>
          </Box>
      </FormControl>
    </Container>
      }
  </>)
}

export default EditClientContainer;