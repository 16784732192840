import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { get, post, put } from '../../api';
import CartridgesEventsComponent, { CartridgesEventsData } from '../../components/cartridgesEvents';
import { DeviceCartridgeEntry } from '../../components/deviceCartridges';

export default function CartridgesEventsContainer() {

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { tagId, deviceId } = useParams();

  const [data, setData] = useState<CartridgesEventsData[]>([]);
  const [cartridgeData, setCartridgeData] = useState<DeviceCartridgeEntry>();
  
  const fetchData = async () => {

    setLoading(true);
    
    const response = await get('cartridge/' + tagId + '/' + deviceId + '/events-and-data');

    setLoading(false);

    if (response?.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    setData(response.data.events);
    setCartridgeData(response.data.data);
  };

  useEffect(()=>{

    fetchData();
  }, [tagId]);

  return <CartridgesEventsComponent
    tag={tagId ?? ''}
    loading={loading}
    cartridgeData={cartridgeData}
    errorMessage={errorMessage}
    data={data}
  />;
}