import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChildCompaniesTable from './childCompaniesTable';
import { KeyboardArrowUp } from '@mui/icons-material';

export type HodDataEntry = {
    name: string,
    id: number,
    companies: []
}

type ComponentProps = {
  data: Array<HodDataEntry>,
  errorMessage?: string,
  loading: boolean
}


export default function HodsComponent ({ data, errorMessage, loading }: ComponentProps) {

  const theme = useTheme();
  const navigate = useNavigate();

  const [openedCompanies, setOpenedCompanies] = useState<number[]>([]);

  const handleOpenCompanies = (id: number) => {

    const newOpened = [...openedCompanies];

    const indx = newOpened.indexOf(id);

    if (indx > -1) {
      newOpened.splice(indx,1);
    } else {
      newOpened.push(id);
    }
    setOpenedCompanies(newOpened);
  };


  data = data.map(x=>{

    if (openedCompanies.includes(x.id)) {
      return { ...x, ...{ subRow: <ChildCompaniesTable data={x.companies} /> } };
    }

    return x;
  });

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title={'Settings for HODs and Clients'}
        subTitle=""
      />
    </Box>
    <LoadingBar loading={loading} />
    {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
    <PortalTable 
      pagination={false}
      headCells={[
        { 
          disablePadding: false,
          id: 'id',
          label: 'Id',
          numeric: true  
        },
        { 
          disablePadding: false,
          id: 'name',
          label: 'Name',
          numeric: false
        },
        { 
          disablePadding: false,
          id: 'clients',
          label: 'Clients',
          numeric: false,
          customCell: (cell: HodDataEntry)=><Box>{cell.companies?.length}</Box>  
        },
        { 
          disablePadding: false,
          id: 'actions',
          label: 'Actions',
          numeric: false,
          customCell: (cell: HodDataEntry)=><Box>
            {!openedCompanies.includes(cell.id) ? <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={()=>handleOpenCompanies(cell.id)} /> : <KeyboardArrowUp sx={{ cursor: 'pointer' }} onClick={()=>handleOpenCompanies(cell.id)} /> }
            <BuildCircleIcon sx={{ cursor: 'pointer' }} onClick={()=>{ navigate('/company-settings/' + cell.id); }} />
          </Box>  
        },
      ]}
      data={data}
    />
  </Box>;
}