import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../api';

import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import CompanySettings, { CompanyData } from '../../components/companySettings/singleCompany';

const CompanySettingsContainer = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState<CompanyData>({
    id: 0,
    name: '',
    address: '',
    settings: []
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    
    setLoading(true);
    const response = await get('companies/' + companyId).catch(console.error);

    setLoading(false);
    if (response.status === 200) {

      setData(response.data);

    } else {
      setError('Failed to load company settings data');
    }
  };
  
  const onSave = async (settingValues: {[key: string]: string}) => {
    

    setLoading(true);
    const response = await post('companies/' + companyId + '/settings', settingValues ).catch(console.error);

    setLoading(false);
    if (response.status === 200) {
      fetchData();

    } else {
      setError('Failed to update data');
    }
  };

  useEffect(()=>{

    fetchData();
  },[]);

  return (
    <CompanySettings error={error} data={data} onSave={onSave} loading={loading} navigate={navigate} />
  );
};

export default CompanySettingsContainer;