import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Settings, { Setting } from '../../components/settings';
import { get, post, put } from '../../api';
import { OrderTypeEntry } from '../../components/orders/orderType';
import StatsDashboard from '../../components/statsDashboard';

export default function StatsDashboardContainer () {

  const [url, setUrl] = useState<string>('');
  const [error, setError] = useState('');


  const getDashboardUrl = async () => {

    const response = await get('statsdashboard');

    
    if (response?.status === 200) {

      setUrl(response.data.url);

    } else {
      setError('Failed to load location data');
    }
  
    return;
  };

  useEffect(()=>{
    getDashboardUrl();
  }, []);
  
  return (<StatsDashboard url={url} error={error} />);
}