import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { get, post, put } from '../../api';
import HodsComponent, { HodDataEntry } from '../../components/companySettings/index';

export default function HodsContainer() {

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<HodDataEntry[]>([]);

  const fetchData = async () => {

    setLoading(true);
    //const response = await get('devices-hods?onlyActive=' + filters.onlyActive + locationFilter);
    const response = await get('owners');

    setLoading(false);

    if (response.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage('');
    }

    setData(response.data);
  };

  useEffect(()=>{
    fetchData();
  },[]);

  return <HodsComponent
    loading={loading}
    errorMessage={errorMessage}
    data={data}
  />;
}