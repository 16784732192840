import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, useTheme } from '@mui/material';
import H2yoLogo from '../common/logos/h2yo';
import FonteVivaLogo from '../common/logos/fonteviva';
import { useSelector } from 'react-redux';
import { getPermissions, selectData } from '../../features/user/userSlice';
import { API_URL, post } from '../../api/index';
import { COMPANY_LOGO_URL, imageExists } from '../companySettings/singleCompany';

export enum MenuTypes {
  HOME = 'home',
  DEVICES = 'devices',
  ORDER_HISTORY = 'order-history',
  TEAM_MANAGEMENT = 'team-management',
  ADDRESSES = 'addresses',
  PROFILE = 'profile',
  COMPANY_ACCOUNT = 'company-account',
  SHOP = 'shop',
  INVITE = 'invite-hod',
  LOGOUT = '',
  LOCATIONS = 'locations',
  CARTRIGES = 'cartriges',
  SERVICES = 'services',
  ORDERS = 'orders',
  CUSTOMER_SUPPORT = 'customer-support',
  SETTINGS = 'settings',
  DEBUG = 'debug',
  DEBUGORDERS = 'orders-debug',
  COMPANIES = 'clients',
  HODS = 'hods',
  MANAGE_ALL_DEVICES = 'manage-devices',
  MANAGE_CLIENT_DEVICES = 'manage-devices',
  STATS_DASHBOARD = 'stats-dashboard',
  ORDER_TRACKING = 'order-tracking',
  MANAGE_ALL_CARTRIDGES = 'device-cartridges',
  DEVICES_HODS = 'devices-hods',
  DEVICES_EVENTS = 'devices-events',
  LOCATION_LOG = 'location-log',
}

const settings = [
  [MenuTypes.LOCATION_LOG, 'Location Log', 'VIEW_FULL_LOCATION_LOG'],
  [MenuTypes.COMPANY_ACCOUNT, 'My company'],
  [MenuTypes.TEAM_MANAGEMENT, 'My team', 'MANAGE_TEAM'],
  [MenuTypes.PROFILE, 'My profile'],
  // [MenuTypes.CUSTOMER_SUPPORT, 'Customer Support'],
  // [MenuTypes.DEBUG, 'Debug', 'DEBUG'],
  // [MenuTypes.DEBUGORDERS, 'Orders', 'DEBUG'],
  [MenuTypes.INVITE, 'Invite', 'INVITE_MENU'],
  [MenuTypes.DEVICES_HODS, 'Devices Owners', 'DEVICE_HOD_MANGE'],
  [MenuTypes.HODS, 'Customer Settings', 'VIEW_OWNERS'],
  [MenuTypes.SETTINGS, 'Portal Settings', 'PORTAL_SETTINGS'],
  [MenuTypes.LOGOUT, 'Logout']
];

const pages = [
//   [MenuTypes.HOME, 'Home'],
  // [MenuTypes.LOCATIONS, 'Locations', 'VIEW_LOCATIONS'],
  // [MenuTypes.CARTRIGES, 'Cartridges', 'VIEW_CARTRIDGES_INFO'],
  // [MenuTypes.ORDERS, 'Orders', 'VIEW_CARTRIDGE_ORDERS'],
  [MenuTypes.COMPANIES, 'Clients', 'VIEW_COMPANIES'],
  [MenuTypes.MANAGE_ALL_DEVICES, 'Devices', 'MANAGE_ALL_DEVICES'],
  [MenuTypes.MANAGE_CLIENT_DEVICES, 'Devices', 'MANAGE_CLIENT_DEVICES'],
  [MenuTypes.STATS_DASHBOARD, 'Stats', 'STATS_DASHBOARD'],
  [MenuTypes.ORDER_TRACKING, 'Orders', 'MANAGE_CUSTOMER_ORDERS'],
  [MenuTypes.MANAGE_ALL_CARTRIDGES, 'Cartridges', 'MANAGE_ALL_CARTRIDGES'],
];

export default function AppBarComp () {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const userData: any = useSelector(selectData);
  
  const [distributorLogo, setDistributorLogo] = React.useState<string | null>(null);

  const theme = useTheme();

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const permissions = useSelector(getPermissions);

  console.log('permissions', permissions)

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const setPageHandler = async (page: MenuTypes) => {

    if (page === MenuTypes.LOGOUT) {
      await post('logout');
    }

    navigate('/' + page);
    
    return;
  };

  const setImage = async () => {

    const src = COMPANY_LOGO_URL.replace('[ID]', userData.ownerId);

    const exists = await imageExists(src);

    if (exists) {
      setDistributorLogo(src);
    }
  };

  React.useEffect(() => {
    setImage();

  }, [userData?.ownerId]);

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.filter(setting=>{
                if (typeof setting[2] === 'undefined') {
                  return true;
                }

                return permissions.includes(setting[2]);

              }).map((page) => (
                <MenuItem key={page[0]} onClick={() => {
                  setPageHandler(page[0] as MenuTypes); 
                  handleCloseNavMenu();
                }}>
                  <Typography textAlign="center">{page[1]}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={() => {
                window.open('https://partner-help.h2yo.co/', '_blank')                  
              }}>
                <Typography textAlign="center">Knowledge Base</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box onClick={()=>navigate('/clients')} sx={{ display: 'flex', cursor: 'pointer', height: '17px', padding: theme.spacing(0, 4, 0, 4) }}>
            <div style={distributorLogo && userData.ownerId ? { borderRight: '1px solid #ffffff', padding: theme.spacing(0, 1, 0, 1) } : { padding: theme.spacing(0, 1, 0, 1) }}><H2yoLogo width={67} height={17} /></div>
            <div style={{ padding: theme.spacing(0, 1, 0, 1), marginTop: '-2px' }}>{ 
              distributorLogo && userData.ownerId ? <img src={distributorLogo || ''} /> : null 
            }</div>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.filter(setting=>{
              if (typeof setting[2] === 'undefined') {
                return true;
              }

              return permissions.includes(setting[2]);

            }).map((page) => (
              <Button
                key={page[0]}
                onClick={() => {
                  setPageHandler(page[0] as MenuTypes); 
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: 'white', display: 'block', margin: theme.spacing(0, 3) }}
              >
                {page[1]}
              </Button>
            ))}
            <Button
                onClick={(e) => {
                  e.preventDefault()
                  window.open('https://partner-help.h2yo.co/', '_blank')
                }}
                sx={{ my: 2, color: 'white', display: 'block', margin: theme.spacing(0, 3) }}
              >
                Knowledge Base
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Avatar sx={{ cursor: 'pointer', bgcolor: theme.palette.primary.main }} onClick={handleOpenUserMenu}>
                <PersonIcon sx={{ color: theme.palette.common.white }} />
              </Avatar>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.filter(setting => {
                if (typeof setting[2] === 'undefined') {
                  return true;
                }

                return permissions.includes(setting[2]);

              }).map((setting) => (
                <MenuItem 
                  key={setting[0]} 
                  onClick={()=> {
                    setPageHandler(setting[0] as MenuTypes);

                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">{setting[1]}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}