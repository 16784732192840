import { Alert, Box, Button, Container, Dialog, DialogTitle, LinearProgress, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import TableCP, { RowData } from '../common/table';
import { InvitedUser, SelectType, UserData } from '../common/types';

export default function TermsAndConditions() {
  

  const theme = useTheme();

  const navigate = useNavigate();

  return <Container>
    <PageHeader
      title="H2YO Terms and Conditions"
      subTitle=""
    />
    <Box>
      <Typography variant="body1">
        Terms and conditions text, sit amet, consectetur adipiscing elit. Fusce et dapibus ex. Pellentesque non suscipit nisl. Pellentesque id venenatis nunc. Sed imperdiet eget augue vitae accumsan. Quisque suscipit turpis id quam tempor faucibus. In pulvinar tellus sit amet diam volutpat dictum. Mauris pharetra, velit id auctor facilisis, dolor sapien ultricies metus, sed tempus arcu neque sed est. Etiam vitae consequat quam, vel maximus arcu. Praesent ac nisi ornare, scelerisque felis quis, egestas turpis.

        Ut porta sollicitudin lectus, venenatis iaculis sem convallis in. Pellentesque tristique purus a dolor malesuada, vitae volutpat sem hendrerit. Phasellus id tortor fermentum, mattis ligula ut, porta elit. Sed sollicitudin ut nulla at ultrices. Aliquam erat volutpat. In eu nisl et nunc molestie lobortis in vel ipsum. Sed nec neque rhoncus neque ornare feugiat quis sed nulla. In hac habitasse platea dictumst. Sed vitae neque vulputate, tempor turpis sit amet, vulputate ante.

        Vivamus at sapien ac ex fermentum consectetur in eleifend dui. Mauris vel erat ultricies elit tincidunt ultricies. Aliquam congue metus risus, sit amet pellentesque magna tristique sed. Vestibulum efficitur posuere iaculis. Aenean eget urna sit amet quam scelerisque mattis. In hac habitasse platea dictumst. Integer quis risus ut tellus gravida ultrices nec sit amet elit. Ut posuere rhoncus vestibulum. Nam sed arcu elit. In tincidunt sapien magna, ultrices vestibulum diam vehicula sit amet. Quisque sollicitudin, turpis sed euismod ornare, risus elit euismod nibh, ac sagittis purus ipsum at dui.
      </Typography>
    </Box>
  </Container>;
}