import { Alert, Box, Container, Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { red, yellow } from '@mui/material/colors';
import React from 'react';
import { CartrigeType, ICartrigesState } from '../../features/cartriges/cartrigesSlice';
import { LoadingBar } from '../common/loading';
import H2yoLogo from '../common/logos/h2yo';
import PageHeader from '../common/page/pageHeader';
import PortalTable, { HeadCell } from '../common/table/portalTable';

enum LevelStatus {
    NORMAL = 'NORMAL',
    LOW_LEVEL = 'LOW_LEVEL'
}

enum OrderStatus {
    NONE = 'NONE',
    PENDING = 'PENDING'
}

export interface ICustomersDataEntry {
    clientId: number,
    clientName: string,
    locationId: number,
    locationName: string,
    hodName: string,
    levelStatus: LevelStatus,
    orderStatus: OrderStatus,
    devicesCount: number,
}

export default function Customers ({ loading, data, error, navigate }: {loading: boolean, navigate: (url: string) => void, error: string, data: ICustomersDataEntry[]}) {

  const theme = useTheme();

  const cellClickHandler = (cell: HeadCell, value: string | number, row: ICustomersDataEntry) => {
    
    switch (cell.id) {
      case 'clientId':
      case 'clientName':
      case 'levelStatus':

        navigate('/manage-devices/?locationId=' + row.locationId);

        break;
      case 'locationId':
      case 'locationName':
      case 'orderStatus':
        navigate('/orders-debug?' + `companies=%5B${row.clientId}%5D&locations=%5B${row.locationId}%5D`);
        break;
      default:
        // code block
    }
    
  };
  
  return <Container sx={{ padding: theme.spacing(0, 6) }}>
    <PageHeader 
      title="Orders" 
      subTitle={<>List of customers and locations</>}
    />
    <LoadingBar loading={loading} />
    <Box>
      {error ? <Box><Alert severity="error">{ error }</Alert></Box> : null}
      <PortalTable
        onCellClick={cellClickHandler}
        headCells={[
          {
            disablePadding: false,
            id: 'clientId',
            label: 'Client ID',
            numeric: true,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'clientName',
            label: 'Client',
            numeric: false,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'locationId',
            label: 'Location ID',
            numeric: true,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'locationName',
            label: 'Location',
            numeric: false,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'hodName',
            label: 'Account Owner',
            numeric: false,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'devicesCount',
            label: 'Active Devices',
            numeric: true,
            cellClickable: false
          },
          {
            disablePadding: false,
            id: 'orderStatus',
            label: 'Create Order',
            numeric: false,
            cellClickable: true
          }
        ]}
        data={data}
        // rowStyle={(x: ICustomersDataEntry)=>{
        //   if (x.levelStatus === LevelStatus.LOW_LEVEL) {
        //     return { backgroundColor: red[100] };
        //   } else if (x.orderStatus === OrderStatus.PENDING) {
        //     return { backgroundColor: yellow[100] };
        //   } else {
        //     return {};
        //   }
        // }}
      />
    </Box>
  </Container>
  ;
}