import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';

export interface DeviceEventsData {
    action: string,
    slot: string,
    createdTime: string,
    deviceName: string,
    deviceId: string
}



type DevicesComponentProps = {
  data: Array<DeviceEventsData>,
  errorMessage?: string,
  loading: boolean,
  deviceSN: string
}

export default function DevicesEventsContainer ({ deviceSN, loading, data, errorMessage }: DevicesComponentProps) {

  const theme = useTheme();
  const navigate = useNavigate();

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title={deviceSN + ' device events'}
        subTitle="All device events"
      />
    </Box>
    <LoadingBar loading={loading} />
    {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
    <PortalTable 
      headCells={[
        { 
          disablePadding: false,
          id: 'deviceName',
          label: 'Device Name',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'createdTime',
          label: 'Created Time',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'slot',
          label: 'Slot',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'action',
          label: 'Action',
          numeric: false  
        },
      ]}
      data={data}
    />
  </Box>;
}