import React, { useState } from 'react';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, Checkbox, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { DevicesFilterProps } from './filterProps';
import { green, red, yellow, grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { SelectType } from '../common/types';
import CartridgesFilter, { DeviceCartFilterType } from '../common/cartridgesFilter';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackBar, { SnackState } from '../common/snackBar';
import BookIcon from '@mui/icons-material/Book';
import { LoadingBar } from '../common/loading';
import { DeviceDataRow, LocationStock } from '.';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../features/user/userSlice';

type TopBarProps = {
    snackState: SnackState,
    snackMessage: string,
    onCloseSnack: () => void,
    errorMessage?: string,
    onReload: () => void,
    onRCU: () => void,
    setFilters: (arg: DevicesFilterProps) => void,
    filters: DevicesFilterProps,
    deviceRefresh: boolean,
    loading: boolean,
    allCartridges: SelectType[],
  }

export default function TopBar ({ errorMessage, loading, snackState, snackMessage, onCloseSnack, deviceRefresh, onReload, onRCU, filters, setFilters }: TopBarProps) {
  const permissions = useSelector(getPermissions);

  const setFiltersHandler = async (filterType: DeviceCartFilterType, values: SelectType[] | Date[]) => {
    
    switch (filterType) {
      case DeviceCartFilterType.companies:
    
        setFilters({ ...filters, ...{ companies: (values as SelectType[]).map(x=>x.id as number) } });
            
        break;
      case DeviceCartFilterType.devices:
        setFilters({ ...filters, ...{ devices: (values as SelectType[]).map(x=>x.id as string) } });
        break;
      case DeviceCartFilterType.locations:
        setFilters({ ...filters, ...{ locations: (values as SelectType[]).map(x=>x.id as number) } });
        break;
    }
  };

  return <><Box sx={{ padding: (theme)=>theme.spacing(2, 2) }}>
    <Typography variant="h4">Manage Devices</Typography>
    <Typography variant="body1">All H2YO Devices</Typography>
  </Box>
  {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
  <LoadingBar loading={loading} />
  <SnackBar snackState={snackState} snackText={snackMessage} onClose={onCloseSnack} />
  <Box sx={{ padding: (theme)=>theme.spacing(2, 2), display: 'flex' }}>
    <WideButton sx={{ margin: (theme: Theme)=>theme.spacing(2) }} disabled={deviceRefresh} onClick={onReload}>Refresh</WideButton>
    {permissions.includes('REFRESH_CARTRIDGE_USAGE') && <WideButton sx={{ margin: (theme: Theme)=>theme.spacing(2) }} disabled={deviceRefresh} onClick={onRCU}>Refresh Cartridge Usage</WideButton>}
    <FormControlLabel
      control={<Switch checked={filters.onlyActive} onChange={()=>setFilters({ ...filters, onlyActive: !filters.onlyActive })} />}
      label="Only Active"
    />
    <FormControlLabel
      control={<Switch checked={filters.showHidden} onChange={()=>setFilters({ ...filters, showHidden: !filters.showHidden })} />}
      label="Show Hidden"
    />
  </Box>
  <Box>
    <CartridgesFilter 
      showDatePicker={false}
      showCartridgeTypes={false}
      onSetFilter={setFiltersHandler}
    />
  </Box></>;
}