import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../api';
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from 'react-router-dom';
import LocationAdd, { AddFormLocationStatus } from '../../components/locations/addLocation';
import { LocationFormType } from '../../components/locations/location';
import Register from '../../components/account/register/register';
import { IRegisterCompState } from '../../components/account/register/IRegisterCompState';
import { RegStage } from '../user/userSlice';
import { authorizationOptions } from '../authorization/index';

const AddClientContainer = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [registrationStage, setRegistrationStage] = useState(RegStage.inProgress);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<AddFormLocationStatus>(AddFormLocationStatus.EDIT);

  const { clientId } = useParams();

  const [formData, setFormData] = useState<LocationFormType>({
    name: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
    country: '',
    country_id: 0,
    x_studio_signee: '',
    x_studio_legal_address: '',
    x_studio_signee_position: '',
    x_studio_legal_name: '',
    x_studio_authorization: '',
    x_studio_invoice_email: '',
    x_studio_bank: '',
    x_studio_iban: '',
  });

  const formDataHandler = (data: LocationFormType) => {
    setFormData(data);
  };

  const addHandler = async (data: IRegisterCompState) => {

    const urlParams = new URLSearchParams(window.location.search);
    const dataToSend: any = data;

    if (data.x_studio_authorization_id) {
      dataToSend.x_studio_authorization = authorizationOptions.filter(x => x.id === data.x_studio_authorization_id)[0].name;
      delete dataToSend.x_studio_authorization_id
    }

    dataToSend.token = urlParams.get('token');
    dataToSend.inviteEmail = urlParams.get('email');
    const result = await post('companies-register', dataToSend);

    if (result.status === 200) {
      setError('');
      setMessage('Registration completed!');
      setRegistrationStage(RegStage.completed);
    } else {
      if (result.message) {
        setError(result.message);

      } else {
        setError('An error occured! Failed to Register!');
      }
    }
  };

  return (
    <Register
      titlePerson="User Details"
      message={message} 
      onRegister={addHandler}
      registerError={error}
      disabledProps={[]}
      registrationStage={registrationStage}
      step={0}
      hideTerms={true}
      useCase={'AddCompany'}
    />
  );
};

export default AddClientContainer;