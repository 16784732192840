import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { get, post, put } from '../../api';
import { ICartridgeFilterState } from '../../components/common/cartridgesFilter';
import { SelectType } from '../../components/common/types';
import Devices from '../../components/devices';
import { DevicesFilterProps } from '../../components/devices/filterProps';
import LocationLog, { DeviceEventsData } from '../../components/devicesEvents';
import DevicesHods, { DevicesHodEntry } from '../../components/devicesHods';
import LocationLogComponent from '../../components/locationLog';

export default function LocationLogContainer() {

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { deviceSN } = useParams();

  const [data, setData] = useState<DeviceEventsData[]>([]);
  
  const fetchData = async (locationID: number, date: Date) => {

    setLoading(true);
    
    const response = await get('location-log/' + locationID + '?date=' + encodeURIComponent(JSON.stringify(date)));

    setLoading(false);

    if (response.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    setData(response.data);
  };

  const onSetFilters = (filters: ICartridgeFilterState) => {

    if (filters.locations[0] && filters.date[0]) {
      fetchData(filters.locations[0], filters.date[0]);
    }
  };

  return <LocationLogComponent
    onSetFilters={onSetFilters}
    deviceSN={deviceSN ?? ''}
    loading={loading}
    errorMessage={errorMessage}
    data={data}
  />;
}