import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { get, post, put } from '../../api';
import { SelectType } from '../../components/common/types';
import Devices from '../../components/devices';
import { DevicesFilterProps } from '../../components/devices/filterProps';
import DevicesHods, { DevicesHodEntry } from '../../components/devicesHods';

export default function DevicesSNContainer() {

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceRefresh, setDeviceRefresh] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [hodsOptions, setHodsOptions] = useState<SelectType[]>([]);
  const [data, setData] = useState<DevicesHodEntry[]>([]);

  const [filters, setFilters] = useState<DevicesFilterProps>({
    onlyActive: true
  });

  const changeHandler = async (serialNumber: string, value: SelectType) => {

    let previousDataEntry: DevicesHodEntry;

    setData(data.map(x=>{

      if (x.serialNumber === serialNumber) {
        previousDataEntry = { ...x };
        x.ownerId = value.id as number;
        x.ownerName = value.name;
      }
      return x;
    }));

    setLoading(true);
    const response = await post('devices-owners/' + serialNumber, { ownerId: value.id });
    setLoading(false);

    if (response.status !== 200) {
      setErrorMessage(response.message);

      setData(data.map(x=>{

        if (x.serialNumber === serialNumber) {
          return previousDataEntry;
        }
        return x;
      }));
      return;
    }



  };

  const fetchData = async () => {

    setDeviceRefresh(true);

    let locationFilter = '';
    if (searchParams.get('locationId')) {
      locationFilter = '&locationId=' + searchParams.get('locationId');
    }

    setLoading(true);
    //const response = await get('devices-hods?onlyActive=' + filters.onlyActive + locationFilter);
    const response = await get('devices-owners');

    setLoading(false);
    setDeviceRefresh(false);

    if (response.status !== 200) {
      setErrorMessage(response.message);
      return;
    }

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    setData(response.data.data);
    setHodsOptions(response.data.ownerOptions);
  };

  useEffect(()=>{

    fetchData();
  }, [filters.onlyActive, searchParams.get('locationId')]);

  return <DevicesHods
    loading={loading}
    error={errorMessage}
    onChange={changeHandler}
    hodsOptions={hodsOptions}
    data={data}
  />;
}