import { Alert, Box, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MultiSelectChip from '../common/input/multiSelectChip';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import TableCP, { RowData } from '../common/table';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { SelectType } from '../common/types';
import { OrderTypeEntry } from './orderType';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import useSWR from 'swr';
import { get } from '../../api';

const OrderedProducts = ({ orderId, setErrorInternal }: {orderId: number, setErrorInternal: (error: string) => void})=>{
  
  const { data, error } = useSWR('orders/'+ orderId + '/products', get);
  

  if (error) {

    setErrorInternal(data?.message ?? 'An error occured! Could not fetch products');
    return null;
  }

  if (data?.status && data.status !== 200) {
    setErrorInternal(data?.message ?? 'An error occured! Could not fetch products');
    return null;
  }

  if (!data) {

    return <Box><LoadingBar loading={true} /></Box>;
  }

  setErrorInternal('');

  return <Table size="small" sx={{ margin: (theme: any)=> theme.spacing(0, 0, 4, 0) }}>
    <TableHead>
      <TableRow>
        <TableCell>Product</TableCell>
        <TableCell align="right" width={100}>Quantity</TableCell>
        <TableCell align="right" width={100}>Cost (EUR)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data?.data?.map((x: any, i: number)=>{

        return <TableRow key={i}>
          <TableCell>{x.name}</TableCell> <TableCell align="right" width={100}>{x.product_qty}</TableCell> <TableCell align="right" width={100}>{x.price_total} EUR</TableCell>
        </TableRow>;
      })}
    </TableBody></Table>;
};

interface OrderTypeTableEntry extends OrderTypeEntry {
  subRow?: JSX.Element
}

export default function Orders({ data, locations, error, loading }: {data: OrderTypeTableEntry[], loading: boolean, locations: SelectType[], error: string }) {
    
  const theme = useTheme();

  const [locationsFiltred, setLocationsFiltred] = useState<SelectType[]>([]);
  const [openOrders, setOpenOrders] = useState<Number[]>([]);
  const [orderProducts, setOrderProducts] = useState<{}>({});
  const [errorInternal, setErrorInternal] = useState('');


  useEffect(()=>{

    setErrorInternal(error);
  }, [error]);

  const selectOptionsHandler = (filterVal: SelectType[] | SelectType) => {

    setLocationsFiltred(filterVal as SelectType[]);
    return;
  };

  const isOpen = (row: OrderTypeEntry) => {

    return openOrders.includes(row.id);
  };

  const setOpenHandler = (row: OrderTypeEntry) => {

    if (isOpen(row)) {
      
      setOpenOrders(openOrders.filter(x=>x !== row.id));
    } else {

      openOrders.push(row.id);
      setOpenOrders([...openOrders]);
    }

  };

  const headCells: HeadCell[] = [
    {
      disablePadding: false,
      id: 'toggler',
      label: '',
      numeric: false,
      width: 10,
      customCell: (row: OrderTypeEntry) => (
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpenHandler(row)}
        >
          {isOpen(row) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      ),
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      width: 10
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
    },{
      id: 'cost',
      numeric: true,
      disablePadding: false,
      label: 'Cost (EUR)',
    }
  ];

  const filterIds = locationsFiltred.map(x=>x.id);

  if (locationsFiltred.length > 0) {
    data = data.filter(x => filterIds.includes(x.location.id));
  }
  
  data = data.map(x => {

    if (isOpen(x)) {
      
      x.subRow = <OrderedProducts setErrorInternal={setErrorInternal} orderId={x.id} />;
    } else {
      x.subRow = undefined;
    }
    

    return x;
  });

  return <Container>
    <PageHeader
      title="Orders"
      subTitle="Keep Track of your orders"
    />
    <LoadingBar loading={loading} />
    {errorInternal ? <Grid item><Alert severity="error">{errorInternal}</Alert></Grid> : null}
    <Box>
      <MultiSelectChip 
        label="Locations"
        value={locationsFiltred}
        selectOptions={locations}
        onSelectOption={selectOptionsHandler}
      />
      <PortalTable 
        headCells={headCells}
        data={data} 
      />
    </Box>
  </Container>;
}