import React from 'react';
import SelectInput from '../../components/common/input/select';
import { SelectType } from '../../components/common/types';

type SelectAuthorizationType = {
    label: string,
    onChange: (id: number) => void,
    value: number | string,
    error?: boolean,
    disabled?: boolean
    fullWidth?: boolean
}

export const authorizationOptions = [
  {id: 1, name: 'pilnvaras'},
  {id: 2, name: 'statūtu'},
  {id: 3, name: 'prokūras'}
]

export default function SelectAuthorization ({ label, onChange, value, disabled, error, fullWidth }: SelectAuthorizationType) {
  console.log('all props: ', label, value, disabled, error, fullWidth);

    const SelectInputHandler = (option: SelectType) => {

    if (typeof option.id === 'number') {
      onChange(option.id);
    } else {
      onChange(parseInt(option.id));
    }
  };

  return (<SelectInput
    error={error}
    disabled={disabled}
    label={label}
    selectOptions={authorizationOptions}
    value={{ id: value }}
    fullWidth={fullWidth}
    onSelectOption={SelectInputHandler}
  />);
}