import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import { DeviceCartridgeEntry } from '../deviceCartridges';

export interface CartridgesEventsData {
    tagId: string,
    event: string,
    slot: string,
    time: string,
    cloudantId: string
}

type ComponentProps = {
  data: Array<CartridgesEventsData>,
  errorMessage?: string,
  loading: boolean,
  tag: string,
  cartridgeData?: DeviceCartridgeEntry
}

export default function CartridgesEventsComponent ({ tag, loading, data, errorMessage, cartridgeData }: ComponentProps) {

  const theme = useTheme();
  const navigate = useNavigate();

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title={tag + ' Cartridge'}
        subTitle="Single cartridge data and events log"
      />
    </Box>
    <LoadingBar loading={loading} />
    {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
    <Box padding={theme.spacing(0, 0, 1, 0)}>
      <Typography color={theme.palette.primary.dark} variant="h5" component="div" align="left">
        Cartridge Data
      </Typography>
      <PortalTable
        pagination={false}
        headCells={[
          { 
            disablePadding: false,
            id: 'cartridgeType',
            label: 'Cartridge Type',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'isActive',
            label: 'Is Active',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'deviceName',
            label: 'Device',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'companyName',
            label: 'Customer',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'locationName',
            label: 'Location',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'totalPumped',
            label: 'Total Pumped',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'remaining',
            label: 'Remaining',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'totalInstalls',
            label: 'Total Installs',
            numeric: true  
          },
          { 
            disablePadding: false,
            id: 'lastSlot',
            label: 'Last Slot',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'daysInDevice',
            label: 'Days in Device',
            numeric: true
          },
          { 
            disablePadding: false,
            id: 'daysRemoved',
            label: 'Days Removed',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'firstInstalledTime',
            label: '1st Installed Time',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'lastRemovedTime',
            label: 'Last Removed Time',
            numeric: false  
          },
          { 
            disablePadding: false,
            id: 'reason',
            label: 'Reason',
            numeric: false  
          }
        ]}
        data={cartridgeData ? [cartridgeData] : []}
      />
    </Box>
    <Typography color={theme.palette.primary.dark} variant="h5" component="div" align="left">
        Cartridge Log
    </Typography>
    <PortalTable 
      headCells={[
        {
          disablePadding: false,
          id: 'time',
          label: 'Time',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'event',
          label: 'Event',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'slot',
          label: 'Slot',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'cloudantId',
          label: 'Cloudant ID',
          numeric: false  
        }
      ]}
      data={data}
    />
  </Box>;
}