import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import SelectCountry from '../../../features/countries/selectContainer';
import SelectAuthorization from '../../../features/authorization';

type CompanyDetailsProps = {
  data: any,
  disabledProps: Array<string>,
  errorFields?: Array<string>,
  onSetData: React.Dispatch<React.SetStateAction<any>>
}

export default function CompanyDetails({ disabledProps, onSetData, data, errorFields }: CompanyDetailsProps){

  return <>
    <Grid
      container
      spacing={1}
    >
      <Grid item md={6} sm={6} xs={12}>
        <TextField
          size="small" 
          fullWidth
          label="Company name"
          variant="outlined" 
          value={data.name} 
          error={errorFields?.includes('name')}
          disabled={disabledProps.includes('name')}
          onChange={(ev)=>{onSetData({ ...data, ...{ name: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small"
          label="VAT reg Nr."
          variant="outlined"
          value={data.vat}
          error={errorFields?.includes('vat')}
          disabled={disabledProps.includes('vat')}
          onChange={(ev)=>{onSetData({ ...data, ...{ vat: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Address Line 1"
          variant="outlined" 
          value={data.street} 
          error={errorFields?.includes('street')}
          disabled={disabledProps.includes('street')}
          onChange={(ev)=>{onSetData({ ...data, ...{ street: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Address Line 2"
          variant="outlined" 
          value={data.street2} 
          error={errorFields?.includes('street2')}
          disabled={disabledProps.includes('street2')}
          onChange={(ev)=>{onSetData({ ...data, ...{ street2: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="City / Town" 
          variant="outlined" 
          value={data.city} 
          error={errorFields?.includes('city')}
          disabled={disabledProps.includes('city')}
          onChange={(ev)=>{onSetData({ ...data, ...{ city: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <SelectCountry 
          label="Country / Region"
          value={data.country_id} 
          error={errorFields?.includes('country')}
          disabled={disabledProps.includes('country')}
          onChange={(id: number)=>{onSetData({ ...data, ...{ country_id: id } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="ZIP" 
          variant="outlined" 
          value={data.zip} 
          error={errorFields?.includes('zip')}
          disabled={disabledProps.includes('zip')}
          onChange={(ev)=>{onSetData({ ...data, ...{ zip: ev.target.value } });}}
        />
      </Grid>
    </Grid>
    {/* Add Material UI divider here */}
    <br/>
    <Divider variant="middle" />
    <br/>

    <Typography variant="h6" gutterBottom component="div" align="left">Contract info</Typography>
    <Grid
      container
      spacing={1}
    >
      <Grid item md={6} sm={6} xs={12}>
        <TextField
          fullWidth
          size="small"
          label="Signee"
          variant="outlined"
          value={data.x_studio_signee}
          error={errorFields?.includes('x_studio_signee')}
          disabled={disabledProps.includes('x_studio_signee')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_signee: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Legal Address" 
          variant="outlined" 
          value={data.x_studio_legal_address} 
          error={errorFields?.includes('x_studio_legal_address')}
          disabled={disabledProps.includes('x_studio_legal_address')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_legal_address: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Signee position" 
          variant="outlined" 
          value={data.x_studio_signee_position} 
          error={errorFields?.includes('x_studio_signee_position')}
          disabled={disabledProps.includes('x_studio_signee_position')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_signee_position: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Legal Name" 
          variant="outlined" 
          value={data.x_studio_legal_name} 
          error={errorFields?.includes('x_studio_legal_name')}
          disabled={disabledProps.includes('x_studio_legal_name')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_legal_name: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <SelectAuthorization
          label="Authorization"
          value={data.x_studio_authorization_id} 
          error={errorFields?.includes('x_studio_authorization_id')}
          disabled={disabledProps.includes('x_studio_authorization_id')}
          onChange={(id: number)=>{onSetData({ ...data, ...{ x_studio_authorization_id: id } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Invoice email" 
          variant="outlined" 
          value={data.x_studio_invoice_email} 
          error={errorFields?.includes('x_studio_invoice_email')}
          disabled={disabledProps.includes('x_studio_invoice_email')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_invoice_email: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Bank" 
          variant="outlined" 
          value={data.x_studio_bank} 
          error={errorFields?.includes('x_studio_bank')}
          disabled={disabledProps.includes('x_studio_bank')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_bank: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="IBAN" 
          variant="outlined" 
          value={data.x_studio_iban} 
          error={errorFields?.includes('x_studio_iban')}
          disabled={disabledProps.includes('x_studio_iban')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_iban: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Free Drinks" 
          variant="outlined" 
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10000 } }}
          value={data.x_studio_free_drinks} 
          error={errorFields?.includes('x_studio_free_drinks')}
          disabled={disabledProps.includes('x_studio_free_drinks')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_free_drinks: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Fixed Discount" 
          variant="outlined" 
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10000 } }}
          value={data.x_studio_fixed_discount} 
          error={errorFields?.includes('x_studio_fixed_discount')}
          disabled={disabledProps.includes('x_studio_fixed_discount')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_fixed_discount: ev.target.value } });}}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField 
          fullWidth
          size="small" 
          label="Device Rent Price" 
          variant="outlined" 
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10000 } }}
          value={data.x_studio_device_rent_price_eur} 
          error={errorFields?.includes('x_studio_device_rent_price_eur')}
          disabled={disabledProps.includes('x_studio_device_rent_price_eur')}
          onChange={(ev)=>{onSetData({ ...data, ...{ x_studio_device_rent_price_eur: ev.target.value } });}}
        />
      </Grid>
    </Grid>
  </>;
}