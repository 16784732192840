import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { get, post, put } from "../../api";
import RenderAccumData from "./renderAccumData";
import { CartridgeTypeData } from "./types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import e from "express";
import { LoadingBar } from "../common/loading";
import CartridgesFilter, {
  ICartridgeFilterState,
} from "../common/cartridgesFilter";
// import CartridgeFilter, { ICartridgeFilterState } from '../common/cartridgeFilter';
import { ICartrigesState } from "../../features/cartriges/cartrigesSlice";
import { useSelector } from "react-redux";
import { selectCartridgeFilterData } from "../../features/cartridgeFilterData/cartridgeFilterDataSlice";
import { Setting } from "../settings";

const cartrigeIDs = ["1111", "2222", "3333", "4444", "5555", "6666", "7777"];

type OrderType = { [key: string]: number };

type CartridgeType = {
  id?: string;
  isDisabled: boolean;
  name?: string;
  volume: number;
};

interface IAccuParams {
  companyId: number;
  locationId: number;
  cartridges: string[];
}

export default function OrderDebugComponent() {
  const [accumParams, setAccumParams] = useState<IAccuParams>({
    companyId: 0,
    locationId: 0,
    cartridges: [],
  });
  const [accumData, setAccumData] = useState<Array<CartridgeTypeData>>([]);
  const [orderData, setOrderData] = useState<OrderType>({});
  const [stock, setStock] = useState<Array<any>>([]);
  const [settings, setSettings] = useState<Setting[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);
  const [isOrderEnabled, setIsOrderEnabled] = useState<boolean>(false);

  const [cartridgeTypes, setCartridgeTypes] = useState([]);

  const cartridgeFilterData = useSelector(selectCartridgeFilterData);

  console.log("cartridgeFilterData", cartridgeFilterData);

  const location = useLocation();

  const [filters, setFilters] = useState({
    onlyActive: true,
  });

  const { locationId } = useParams();

  const navigate = useNavigate();

  const loadSettings = async () => {
    const response = await get("settings");

    if (response.status === 200) {
      setSettings(response.data);
    }
    return;
  };

  useEffect(() => {
    console.log("isLocationSel", isLocationSelected);
  }, [isLocationSelected]);

  useEffect(() => {
    // TODO: get all cartridge types
    getCartridgeTypes();
    loadSettings();

    if (locationId) {
      setAccumParams({
        ...accumParams,
        ...{ locationId: parseInt(locationId) },
      });
      getAccumDataHandler(parseInt(locationId));
    } else {
      getAccumDataHandler();
    }
  }, []);

  const getCartridgeTypes = async () => {
    const { cartridgeTypes } = await get("cartridges-filter");
    setCartridgeTypes(cartridgeTypes);
  };

  useEffect(() => {
    if (locationId) {
      setAccumParams({
        ...accumParams,
        ...{ locationId: parseInt(locationId) },
      });
      getAccumDataHandler(parseInt(locationId));
    }
  }, [locationId]);

  const getAccumDataHandler = async (locationParamId?: number) => {
    setLoading(true);

    let cartridgesFilterParam = "";
    if (accumParams.cartridges.length > 0) {
      cartridgesFilterParam =
        "?cartridgeTypes=" +
        encodeURIComponent(JSON.stringify(accumParams.cartridges));
    }

    console.log("getAccumDataHandler", accumParams);
    const payload: any = await get(
      "cartrige-accumulation/" +
        (locationParamId ? locationParamId : accumParams.locationId) +
        cartridgesFilterParam
    );

    setLoading(false);

    if (payload.status === 200) {
      setAccumData(payload.data.accumulation);
      setStock(payload.data.stock);

      const orderData: { [key: string]: number } = {};
      payload.data.accumulation.map(
        (x: CartridgeTypeData) =>
          (orderData[x.cartridgeId] = Math.round(x.cartridgeOrderCount))
      );
      setOrderData(orderData);
      setErrorMessage("");
    } else {
      setErrorMessage(payload.message);
    }
  };

  const orderHandler = async () => {
    setLoading(true);

    const payload = await post(
      "order/repeatorder/" + accumParams.locationId,
      orderData
    );

    setLoading(false);

    if (payload.status === 200) {
      getAccumDataHandler(accumParams.locationId);
    } else {
      setErrorMessage(payload.message);
    }
  };

  const updateOrderValue = (id: string, cartrigeCount: number) => {
    // const newOrderData = { ...orderData };
    // newOrderData[id] = cartrigeCount;

    // setOrderData(newOrderData);
    console.log("BBBBBBBBBBBBBBB: ", cartrigeCount)

    setOrderData((prev) => {
      return { ...prev, [id]: cartrigeCount };
    });
  };

  const deviceClickHandler = (id: string) => {
    navigate(
      "/device-cartridges/?devices=" +
        encodeURIComponent(JSON.stringify([id])) +
        ""
    );
  };

  const updateStockHandler = async (
    cartridgeTypeId: string,
    cartrigeCount: number
  ) => {
    const oldData = [...accumData];

    const newData = accumData.map((entry) => {
      if (entry.cartridgeId === cartridgeTypeId) {
        entry.currentStock = cartrigeCount;
      }

      return entry;
    });

    setAccumData(newData);

    setLoading(true);

    const locId = (
      accumParams.locationId !== 0 ? accumParams.locationId : locationId
    ) as number;

    const payload = await post("location/" + locId + "/stock-cartridges/", [
      { cartrigeCount: cartrigeCount, cartridgeType: cartridgeTypeId },
    ]);
    //const payload = await put('stock/' + id, { cartrigeCount: cartrigeCount });

    setLoading(false);

    if (payload.status === 200) {
      getAccumDataHandler(locId);
      setErrorMessage("");
    } else {
      setErrorMessage(payload.message);
      setAccumData(oldData);
    }
  };

  const filterChangeHandler = (filterState: ICartridgeFilterState) => {
    setAccumParams({
      ...accumParams,
      ...{
        locationId:
          filterState.locations.length > 0 ? filterState.locations[0] : 0,
        cartridges: filterState.cartridgeTypes,
      },
    });
  };

  const filterAndSortCartridgeTypes = (input: Array<any>) => {
    const data = [...input];

    for (let i = 0; i < data.length; i++) {
      const x = data[i];
      const cartridgeType: CartridgeType = cartridgeTypes.find(
        (y: CartridgeType) => y.id === x.cartridgeId
      ) || {
        isDisabled: false,
        id: "0",
        volume: 0,
      };

      const currentCartridgeDemand = cartridgeType.id
        ? cartridgeFilterData?.cartridgeTypeDemand[cartridgeType.id]
        : null;

      // ORDER_DAYS_OF_STOCK HERE
      const orderDaysOfStock = parseInt(
        settings.find((x) => x.id === "ORDER_DAYS_OF_STOCK")?.value || "20"
      );
      const days_to_ship = parseInt(
        settings.find((x) => x.id === "STOCK_DELIVERY_DAYS")?.value || "20"
      );
      // const qtyToOrder = currentCartridgeDemand
      //   ? (currentCartridgeDemand.dailyUsage * orderDaysOfStock) /
      //       cartridgeType.volume -
      //     parseInt(currentCartridgeDemand.currentStock)
      //   : 0;
      // const qtyToOrder = currentCartridgeDemand ? currentCartridgeDemand.dailyUsage * orderDaysOfStock / cartridgeType.volume - parseInt(currentCartridgeDemand.currentStock) : 0
      // sugested_order = (Days_of_stock + days_to_ship - days_left) *  (demand / days_of _stock)
      // const days_to_ship = 5;

      const currentStock = currentCartridgeDemand
        ? parseInt(currentCartridgeDemand.currentStock)
        : 0;
      const averageDemandIn30Days = currentCartridgeDemand
        ? currentCartridgeDemand.dailyUsage * 30
        : 0;
      const suggested =
        ((orderDaysOfStock + days_to_ship) * averageDemandIn30Days) /
          30 /
          cartridgeType.volume -
        currentStock;

      data[i] = {
        ...data[i],
        currentStock: currentStock,
        volume: cartridgeType.volume,
        willLastThisManyDays: currentCartridgeDemand?.willLastThisManyDays || 0,
        qtyToOrder: suggested,
        // qtyToOrder: qtyToOrder > 0 ? qtyToOrder : 0,
        avgDemand: currentCartridgeDemand
          ? currentCartridgeDemand.dailyUsage * 30
          : 0,
      };

      if (cartridgeType.isDisabled) {
        data.splice(i, 1);
        i--;
      }
    }

    data.sort((a, b) => {
      console.log("XXXXXXXXXXXXXXXXXXX", {
        a, b
      })

      if(!a.cartridgeName || !b.cartridgeName) {
        return 0;
      }
      const nameA = a.cartridgeName.toUpperCase();
      const nameB = b.cartridgeName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });

    return data;
  };

  const checkIfOrderDisabled = () => {
    // if theres at least one value larger than 0 return false
    const keys = Object.keys(orderData);

    const result = keys.some((obj) => orderData[obj] > 0);

    setIsOrderEnabled(!isLocationSelected || !result);
  };

  useEffect(() => {
    checkIfOrderDisabled();
  }, [orderData]);

  const accumDataFiltred =
    isLocationSelected && location.search.includes("location")
      ? filterAndSortCartridgeTypes(accumData)
      : [];

  return (
    <Box>
      {errorMessage ? (
        <Grid item>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      ) : null}
      <Grid container alignItems={"center"} padding={5} spacing={2}>
        <Grid item sx={{ margin: "5px 0", border: "1px solid gray" }}>
          <Grid item md={12} sm={12} xs={12}>
            <CartridgesFilter
              showDevice={false}
              showCartridgeTypes={false}
              onSetFilters={filterChangeHandler}
              singleCustomer
              singleLocation
              setIsLocationSelected={setIsLocationSelected}
              updateData={() => getAccumDataHandler()}
            />
          </Grid>
          <LoadingBar loading={loading} />
          <Box sx={{ display: "flex", alignContent: "flex-end" }} />
          <Grid item md={12} sm={12} xs={12}>
            <h4>Order Accumulation:</h4>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Days left</TableCell>
                    {/* <TableCell>Cartridges in devices</TableCell> */}
                    <TableCell>Average Demand (ml / 30 days)</TableCell>
                    <TableCell>Average Demand (cartridges / 30 days)</TableCell>
                    {/*<TableCell>Cartridges</TableCell>*/}
                    {/* <TableCell>Current Stock</TableCell> */}
                    {/* <TableCell>Stock update</TableCell> */}
                    {/* <TableCell>In transit</TableCell> */}
                    <TableCell>Suggested order</TableCell>
                    <TableCell>Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLocationSelected &&
                    accumDataFiltred.map((x, i) => (
                      <RenderAccumData
                        onDeviceClick={deviceClickHandler}
                        key={i}
                        data={x}
                        rowIndx={i}
                        onOrderValueChange={updateOrderValue}
                        onStockUpdate={updateStockHandler}
                        orderValue={orderData[x.cartridgeId]}
                      />
                    ))}
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell colSpan={2}>
                      Total cartridges to order:{" "}
                      {(() => {
                        console.log("orderData", {
                          orderData,
                          values: Object.values(orderData),
                        });
                        return Object.values(orderData).reduce(
                          (a, b) => a + b,
                          0
                        );
                      })()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                sx={{ margin: 1 }}
                size="medium"
                variant="contained"
                onClick={() => orderHandler()}
                disabled={isOrderEnabled}
              >
                Order
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
