import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { green, red, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { SelectType } from '../common/types';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import CartridgesFilter, { DeviceCartFilterType } from '../common/cartridgesFilter';

export type DeviceCartridgeEntry = {
  cartridgeType: string,
  deviceName: string,
  deviceId: string,
  tagId: string,
  isActive: string,
  totalPumped: number,
  remaining: string,
  locationID: number,
  companyID: number,
  totalInstalls: number, 
  lastSlot: string, 
  daysInDevice: number, 
  daysRemoved: number, 
  firstInstalledTime: string, 
  lastRemovedTime: string, 
  reason: string,
}

export type FilterProps = {
    devices: SelectType[],
    locations: SelectType[],
    companies: SelectType[],
    cartridgeTypes: SelectType[],
    date: Date | undefined
}

export type FilterOptionsProps = {
    devices: SelectType[],
    locations: SelectType[],
    companies: SelectType[],
    cartridgeTypes: SelectType[],
}



type DevicesComponentProps = {
  data: Array<DeviceCartridgeEntry>,
  errorMessage?: string,
  loading: boolean,
  onSetFilters: (filterType: DeviceCartFilterType, arg: SelectType[]) => void,
  filterValues: FilterProps
}

export default function DeviceCartridgesComponent ({ loading, data, errorMessage, onSetFilters, filterValues }: DevicesComponentProps) {

  const theme = useTheme();
  const navigate = useNavigate();

  const cellClickHandler = (cell: HeadCell, value: any, row: DeviceCartridgeEntry) => {

    if (cell.id === 'locationId') {
      navigate('/orders-debug/' + value);
    } else if (cell.id === 'tagId') {
      navigate('/cartridge-events/' + row.tagId + '/' + row.deviceId);
    }
    
  };

  return <Box>
    <Box sx={{ padding: (theme) => theme.spacing(0, 2) }}>
      <PageHeader
        title="Cartridges"
        subTitle="All H2YO Cartridges"
      />
    </Box>
    <LoadingBar loading={loading} />
    {errorMessage ? <Box><Alert severity="error">{errorMessage}</Alert></Box> : null}
    {filterValues.companies.length === 0 && filterValues.devices.length === 0 ? <Box><Alert severity="warning">Select customers to see data!</Alert></Box> : null}
    <CartridgesFilter
      showDatePicker={true}
      onSetFilter={onSetFilters}
    />
    {/*<CartridgesFilter
      onSetFilters={onSetFilters}
      filterValues={filterValues}
      options={{
        companies: optionsCompanies,
        devices: optionsDevices,
        cartridgeTypes: optionsCartridgeTypes,
        locations: optionsLocations,
      }}
    />*/}
    <PortalTable
      headCells={[
        { 
          disablePadding: false,
          id: 'cartridgeType',
          label: 'Cartridge Type',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'tagId',
          label: 'Tag ID',
          numeric: false , 
          cellClickable: '/cartridge-events/:value',
          customCellLink: (cell, row) => '/cartridge-events/' + row.tagId + '/' + row.deviceId //this is used instead of cellClickable
        },
        { 
          disablePadding: false,
          id: 'isActive',
          label: 'Is Active',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'deviceName',
          label: 'Device',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'companyName',
          label: 'Customer',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'locationName',
          label: 'Location',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'totalPumped',
          label: 'Total Pumped',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'remaining',
          label: 'Remaining',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'totalInstalls',
          label: 'Total Installs',
          numeric: true  
        },
        { 
          disablePadding: false,
          id: 'lastSlot',
          label: 'Last Slot',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'daysInDevice',
          label: 'Days in Device',
          numeric: true
        },
        { 
          disablePadding: false,
          id: 'daysRemoved',
          label: 'Days Removed',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'firstInstalledTime',
          label: '1st Installed Time',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'lastRemovedTime',
          label: 'Last Removed Time',
          numeric: false  
        },
        { 
          disablePadding: false,
          id: 'reason',
          label: 'Reason',
          numeric: false  
        }
      ]}
      data={data}
      onCellClick={cellClickHandler}
    />
  </Box>;
}