import React, { useEffect, useMemo, useState } from 'react';
import { DeviceData } from '../../features/devices/devicesSlice';
import { Box } from '@mui/system';
import { Alert, Checkbox, FormControlLabel, Switch, Theme, Typography, useTheme } from '@mui/material';
import PortalTable, { HeadCell } from '../common/table/portalTable';
import { WideButton } from '../common/button';
import { DevicesFilterProps } from './filterProps';
import { green, red, yellow, grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { SelectType } from '../common/types';
import CartridgesFilter, { DeviceCartFilterType } from '../common/cartridgesFilter';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackBar, { SnackState } from '../common/snackBar';
import BookIcon from '@mui/icons-material/Book';
import { LoadingBar } from '../common/loading';
import TopBar from './topBar';
import DevicesTable from './devicesTable';
import { Setting } from '../settings';

export interface DeviceDataRow extends DeviceData {
  subRow: JSX.Element
}

export type LocationStock = {
  [key: number]: {[key: string]: number} 
}

type DevicesComponentProps = {
  snackState: SnackState,
  snackMessage: string,
  onCloseSnack: () => void,
  data: Array<DeviceData>,
  errorMessage?: string,
  onReload: () => void,
  onRCU: () => void,
  setFilters: (arg: DevicesFilterProps) => void,
  filters: DevicesFilterProps,
  deviceRefresh: boolean,
  loading: boolean,
  allCartridges: SelectType[],
  onVisiblityChange: (data: DeviceDataRow) => void,
  settings: Setting[],
}


export default function DevicesComponent (props: DevicesComponentProps) {

  const { loading, data, snackState, onCloseSnack, snackMessage, deviceRefresh, errorMessage, onReload, onRCU, filters, setFilters, allCartridges, onVisiblityChange, settings } = props;

  return <Box>
    <TopBar
      loading={loading}
      snackState={snackState}
      onCloseSnack={onCloseSnack}
      snackMessage={snackMessage}
      deviceRefresh={deviceRefresh}
      errorMessage={errorMessage}
      onReload={onReload}
      onRCU={onRCU}
      filters={filters}
      setFilters={setFilters}
      allCartridges={allCartridges}
    />
    {useMemo(()=><DevicesTable
      data={data}
      allCartridges={allCartridges}
      filters={filters}
      onVisiblityChange={onVisiblityChange}
      settings={settings}
    />, [data, allCartridges, filters])}
  </Box>;
}