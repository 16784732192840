import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { SnackState } from '../../components/common/snackBar';

export enum AutoLogin {
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE'
}

interface IDevicesState {
    autoLogin: AutoLogin
}

const initialState: IDevicesState = {
  autoLogin: AutoLogin.IN_PROGRESS
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoginState: (state, action: PayloadAction<AutoLogin>) => {

      state.autoLogin = action.payload;
    }
  },
});

export const getAutoLoginState = (state: RootState) => state.login.autoLogin;

export const { setLoginState } = loginSlice.actions;

export default loginSlice.reducer;
