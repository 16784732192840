import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../api';
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from 'react-router-dom';
import LocationAdd, { AddFormLocationStatus } from '../../components/locations/addLocation';
import { LocationFormType } from '../../components/locations/location';

const AddLocationContainer = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<AddFormLocationStatus>(AddFormLocationStatus.EDIT);

  const { clientId } = useParams();

  const [formData, setFormData] = useState<LocationFormType>({
    name: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
    country: '',
    country_id: 0,
    x_studio_signee: '',
    x_studio_legal_address: '',
    x_studio_signee_position: '',
    x_studio_legal_name: '',
    x_studio_authorization: '',
    x_studio_invoice_email: '',
    x_studio_bank: '',
    x_studio_iban: '',
  });

  const formDataHandler = (data: LocationFormType) => {



    setFormData(data);
  };

  const locationAddHandler = async (data: LocationFormType) => {

    setLoading(true);
    const response = await post('companies/' + clientId + '/locations', data).catch(console.error);
    setLoading(false);
    if (response?.status === 200) {
      setError('');
      setMessage('Location has been added!');
      setStatus(AddFormLocationStatus.ADDED);

      setFormData({
        name: '',
        street: '',
        street2: '',
        zip: '',
        city: '',
        country: '',
        country_id: 0,
        x_studio_signee: '',
        x_studio_legal_address: '',
        x_studio_signee_position: '',
        x_studio_legal_name: '',
        x_studio_authorization: '',
        x_studio_invoice_email: '',
        x_studio_bank: '',
        x_studio_iban: '',
      });
      
    } else {
      setError('Failed to update location data');
    }

    return;
  };

  return (
    <LocationAdd formData={formData} setFormData={formDataHandler} message={message} status={status} loading={loading} error={error} onAdd={locationAddHandler} />
  );
};

export default AddLocationContainer;