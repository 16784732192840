import { Alert, Box, Button, Container, Dialog, DialogTitle, LinearProgress, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiSelectChip from '../common/input/multiSelectChip';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import TableCP, { RowData } from '../common/table';
import { InvitedUser, SelectType, UserData } from '../common/types';
import InviteDialog from './inviteDialog';
import TeamTable, { TeamTableProps } from './table';

interface TeamProps extends TeamTableProps {
    error: string,
    loading: boolean,
    invitedUsers: InvitedUser[]
    onInviteClose: () => void,
    removeInvitedUser: (id: number) => Promise<void>
}

export default function Team(props: TeamProps) {
    
  const [inviteDialog, setInviteDialog] = useState(false);

  const { error, loading, removeInvitedUser, onInviteClose } = props;

  const theme = useTheme();

  const navigate = useNavigate();

  const invitedUserDeleteHandler = async (user: UserData) => {

    removeInvitedUser(user.id);
  };

  return <Container >
    <PageHeader
      title="My team"
      subTitle="Manage your team and assigned roles."
      rightElement={<Button size="small" sx={{ padding: theme.spacing(0.5, 6, 0.25, 6) }} variant="contained" onClick={()=>{ setInviteDialog(true); }}>Invite new member</Button>
      }
    />
    <LoadingBar loading={loading} />
    {error ? <Alert severity="error">{error}</Alert> : null}
    <Box sx={{ margin: theme.spacing(0, 0, 4, 0) }}>
      <Typography variant="h6">Members</Typography>
      <TeamTable {...props} />
    </Box>
    {props.invitedUsers.length > 0 ? <Box>
      <Typography variant="h6">Invited members</Typography>
      <TeamTable {...props}
        onDeleteMember={invitedUserDeleteHandler}
        disabledCols={['roles','locations']}
        data={props.invitedUsers.map((inv: InvitedUser) => {
        
          return { id: inv.id,
            name: '',
            email: inv.email,
            phone: '',
            roles: inv.roles,
            locations: [] };
        })} />
    </Box> : null}
    <Box sx={{ padding: theme.spacing(4, 0) }} />
    <InviteDialog open={inviteDialog} onClose={()=>{setInviteDialog(false); onInviteClose();}} />
  </Container>;
}