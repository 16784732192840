import { Alert, Box, Container } from '@mui/material';
import React from 'react';
import SelectInput from '../common/input/select';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import PortalTable from '../common/table/portalTable';
import { SelectType } from '../common/types';

export type DevicesHodEntry = {
    serialNumber: string,
    ownerId: number,
    ownerName: string,
    timeCreated: string
}

type DevicesHodsProps = {
    data: DevicesHodEntry[],
    hodsOptions: SelectType[],
    loading: boolean,
    error?: string,
    onChange: (serialNumber: string, data: SelectType) => void
}

const DevicesHods = ({ hodsOptions, data, onChange, error, loading }: DevicesHodsProps) => {

  const selectHandler = (serialNumber: string, value: SelectType) => {
        
    onChange(serialNumber, value);
  };

  return <Container sx={{ padding: (theme)=>theme.spacing(0, 6) }}>
    <PageHeader
      title="Devices Owners" 
      subTitle={<>Set device owners</>}
    />
    <LoadingBar loading={loading} />
    {error ? <Box><Alert severity="error">{ error }</Alert></Box> : null}
    <PortalTable headCells={
      [{
        disablePadding: false,
        id: 'timeCreated',
        label: 'Time Created',
        numeric: false  
      },{
        disablePadding: false,
        id: 'serialNumber',
        label: 'Device S/N',
        numeric: false  
      },
      { 
        disablePadding: false,
        id: 'ownerName',
        label: 'Owner',
        numeric: false,
        customCell: (cellData: any) => {

          return <Box>
            <SelectInput 
              label="" 
              onSelectOption={(value)=>selectHandler(cellData.serialNumber, value)} 
              selectOptions={hodsOptions} 
              value={{ id: cellData.ownerId, name: cellData.distributorName }} 
            />
          </Box>;
        }
      }]
    } 
    data={data} />
  </Container>;
};

export default DevicesHods;