import { Alert, Box, Container, Grid, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import TextFieldCP from '../common/input/textField';

export type Setting = {
    id: string,
    value: string,
    description: string
}

interface ISettingsProps {
    settings: Setting[],
    onUpdate: (setting: Setting) => void,
    error?: string
}

export default function Settings({ settings, error, onUpdate }: ISettingsProps) {
    
  const theme = useTheme();

  return <Container sx={{ padding: theme.spacing(6) }}>
    <Typography variant="h4">Settings</Typography>
    <Typography variant="body1">Change portal settings</Typography>
    {error ? <Grid item><Alert severity="error">{ error }</Alert></Grid> : null}
    <Box>
      {settings.map((x, i)=>(
        <Grid container sx={{ padding: theme.spacing(0.5, 0) }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={i}>
          <Grid md={4} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="ID" 
              disabled={true}
              variant="outlined" 
              value={x.id}
            />
          </Grid>
          <Grid md={4} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="Value" 
              variant="outlined" 
              value={x.value}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>)=>{ onUpdate({ ...settings[i], ...{ value: ev.target.value } }); }}
            />
          </Grid>
          <Grid md={4} sx={{ padding: theme.spacing(0.5) }} item>
            <TextFieldCP
              fullWidth
              size="small" 
              label="Description" 
              variant="outlined" 
              value={x.description}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>)=>{ onUpdate({ ...settings[i], ...{ description: ev.target.value } }); }}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  </Container>;
}