import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InviteContainer from '../../features/invite/container';
import { WideButton } from '../common/button';
import { LoadingBar } from '../common/loading';
import PageHeader from '../common/page/pageHeader';
import ClientCompaniesTable, { RowProps } from './table';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddClientContainer from '../../features/clientCompanies/addClientContainer';


export interface DialogTitleProps {
  open: boolean,
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <DialogTitle color={theme.palette.primary.dark} sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const InviteDialog = ({ open, onClose }: DialogTitleProps) => {

  const theme = useTheme();

  return <Dialog
    sx={{ minWidth: '400px' }}
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <BootstrapDialogTitle open={open} onClose={onClose}>
      Invite Company
    </BootstrapDialogTitle>
    <DialogContent sx={{ padding: theme.spacing(0, 2) }}>
      <Box sx={{ padding: theme.spacing(2, 0), borderTop: '1px solid ' + theme.palette.primary.dark }}>
        <InviteContainer inviteCompany={true} onClose={onClose} />
      </Box>
      
    </DialogContent>
  </Dialog>;
};

const AddNewDialog = ({ open, onClose }: DialogTitleProps) => {

  const theme = useTheme();

  return <Dialog
    sx={{ minWidth: '400px' }}
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <BootstrapDialogTitle open={open} onClose={onClose}>
      Add Company
    </BootstrapDialogTitle>
    <DialogContent sx={{ padding: theme.spacing(0, 2) }}>
      <Box sx={{ padding: theme.spacing(2, 0), borderTop: '1px solid ' + theme.palette.primary.dark }}>
        <AddClientContainer />
      </Box>
    </DialogContent>
  </Dialog>;
};

export default function ClientCompanies ({ data, loading, onInviteClose }: {data: RowProps[], loading: boolean, onInviteClose: () => void}) {

  const theme = useTheme();
  const [inviteDialog, setInviteDialog] = useState(false);
  const [addNewDialog, setAddNewDialog] = useState(false);

  return <Container maxWidth="md" sx={{ padding: (theme)=>theme.spacing(6) }}>
    <PageHeader
      title="Client companies"
      subTitle="List of clients and locations"
      rightElement={<Box display="flex">
        <Box sx={{ margin: theme.spacing(0, 1) }}><WideButton onClick={()=>setAddNewDialog(true)}>Add new</WideButton></Box>
        <Box><WideButton onClick={()=>setInviteDialog(true)}>Invite new</WideButton></Box>
      </Box>
      }
    />
    <LoadingBar loading={loading} />
    <ClientCompaniesTable data={data} />
    <InviteDialog open={inviteDialog} onClose={()=>{setInviteDialog(false); onInviteClose();}} />
    <AddNewDialog open={addNewDialog} onClose={()=>{setAddNewDialog(false); onInviteClose();}} />
  </Container>;
}