import React, { useEffect, useState } from 'react';
import { post } from '../../api';
import Login, { ILoginCompState } from '../../components/account/login';

import {
  BrowserRouter as Router,
  useNavigate,
} from 'react-router-dom';
import { addData, addOdooData, ContactRole, setPermissions } from '../user/userSlice';
import { useDispatch, useStore } from 'react-redux';
import { AutoLogin, setLoginState } from './loginSlice';

const LoginContainer = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async ({ login, password }: ILoginCompState) => {

    setLoading(true);
    const response = await post('auth', { username: login, password: password });

    setLoading(false);

    if (response.status === undefined) {
      setLoginError('Connection to H2YO Portal Backend failed!');
      return;
    }

    if (response.status === 200) {

      dispatch(setLoginState(AutoLogin.DONE));
      dispatch(addData(response.data.data));
      dispatch(addOdooData(response.data.odooData));
      dispatch(setPermissions(response.data.permissions));
      //set user data
      if (response.data?.data?.role === ContactRole.ADMIN) {
        navigate('/manage-devices');
      } else {
        navigate('/clients');
      }
    } else {
      setLoginError('Wrong email or password!');
    }
  };

  return (<>
    <Login 
      onLogin={handleLogin} 
      loginError={loginError}
      loading={loading}
      onForgotPassword={()=>{navigate('/resetpassword');}}
    />
  </>);
};

export default LoginContainer;